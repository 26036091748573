import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Container,
  Slide,
  Grid,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  Card,
  Avatar,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Close,
  Pause,
  Money,
  Check,
  MoreVert,
  Pin,
  CreditCard,
  AccountBox,
  CalendarMonth,
  DoNotDisturb,
  AccountBalance,
  Receipt
} from '@mui/icons-material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Table, SimplePagination } from '../../common'
import ChangePause from './ChangePause'
import ConfirmPayment from './ConfirmPayment'
import LimitedActivation from './LimitedActivation'
import ChangeStartEndDate from './ChangeStartEndDate'
import ChangeTrainer from './ChangeTrainer'
import GenerateInvoice from './GenerateInvoice'

import * as USER_REQUESTS from '../../../api/user'
import * as CLIENTS_REQUESTS from '../../../api/clients'
import {
  GRAY_TEXT_COLOR,
  GREEN_COLOR,
  ORANGE_COLOR,
  SUBSCRIPTION_PAYMENT_STATUS,
  LIGHT_GRAY_BORDER_COLOR,
  PAYMENT_METHOD
} from '../../../utils/constants'
import { numberFomatter } from '../../../utils/helpers/numberFomatter'
import { getStatus } from '../../../utils/helpers/userSubscription'
import { useAuthentication } from '../../../context/auth-context'

const useStyles = makeStyles({
  dialogPaper: {
    borderRadius: 0
  }
})

const PAGE_SIZE = 15

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default ({ onClose, userId, companyId, currency }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user: loggedUser } = useAuthentication()

  const [limitedActivation, setLimitedActivation] = useState()
  const [changeStartEndDate, setChangeStartEndDate] = useState()
  const [confirmPayment, setConfirmPayment] = useState()
  const [changePause, setChangePause] = useState()
  const [anchorElement, setAnchorElement] = useState(null)
  const [selectedUserSubscription, setSelectedUserSubscription] = useState()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [userSubscriptionsCount, setUserSubscriptionsCount] = useState(0)
  const [page, setPage] = useState(1)
  const [changeTrainer, setChangeTrainer] = useState()
  const [generateInvoice, setGenerateInvoice] = useState()

  const computeSubscriptionStatus = (status, isDeleted) => {
    if (isDeleted) {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <DoNotDisturb color="error" />
          <Typography fontWeight="bold" color="error">
            {t('clients.CANCELED')}
          </Typography>
        </Box>
      )
    }

    if (status.paused) {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Pause style={{ color: ORANGE_COLOR }} />
          <Typography fontWeight="bold" color={ORANGE_COLOR}>
            {t('clients.pause')}
          </Typography>
        </Box>
      )
    }

    if (status.expired) {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Close color="error" />
          <Typography fontWeight="bold" color="error">
            {t('clients.EXPIRED')}
          </Typography>
        </Box>
      )
    }

    if (status.inactive) {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Close color="error" />
          <Typography fontWeight="bold" color="error">
            {t('clients.inactive')}
          </Typography>
        </Box>
      )
    }

    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Check color="success" />
        <Typography fontWeight="bold" color={GREEN_COLOR}>
          {t('clients.active')}
        </Typography>
      </Box>
    )
  }

  const renderPaymentMethod = (method, isBt) => {
    if (method === PAYMENT_METHOD.CARD && !isBt) {
      return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={t('subscriptionModal.pos')}>
            <Avatar
              variant="square"
              src={require('../../../assets/pos.png')}
              style={{ width: 25, height: 25, paddingRight: 8 }}
            />
          </Tooltip>
        </Box>
      )
    }

    if (method === PAYMENT_METHOD.CARD && isBt) {
      return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={t('subscriptionModal.card')}>
            <CreditCard sx={{ paddingRight: 1 }} color="success" />
          </Tooltip>
        </Box>
      )
    }

    if (method === PAYMENT_METHOD.BANK_TRANSFER) {
      return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={t('subscriptionModal.BANK_TRANSFER')}>
            <AccountBalance sx={{ paddingRight: 1 }} color="success" />
          </Tooltip>
        </Box>
      )
    }

    return (
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Tooltip title={t('subscriptionModal.cash')}>
          <Money sx={{ paddingRight: 1 }} color="success" />
        </Tooltip>
      </Box>
    )
  }

  const computePaidStatus = (status, paymentMethod, isBt) => {
    if (status.paid) {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {renderPaymentMethod(paymentMethod, isBt)}
        </Box>
      )
    }

    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Close color="error" />
        <Typography display="flex" fontWeight={500} color="error" pl={1}>
          {t('clients.NO')}
        </Typography>
      </Box>
    )
  }

  const getUser = () => {
    return USER_REQUESTS.getById(userId)
      .then((result) => {
        setUser(result)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getUserSubscriptions = () => {
    return CLIENTS_REQUESTS.getClientsBusiness(loggedUser.companyId, {
      userId: [userId],
      from: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE
    }).then(({ count, rows }) => {
      setUserSubscriptions(rows)
      setUserSubscriptionsCount(count)
    })
  }

  const computeRevenue = () => {
    if (!userSubscriptions.length) return 0
    return userSubscriptions.reduce((prevValue, currValue) => {
      if (currValue.status === SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED) {
        return prevValue + currValue.Subscription.priceRon
      }
      return prevValue
    }, 0)
  }

  const computeLostRevenue = () => {
    if (!userSubscriptions.length) return 0
    return userSubscriptions.reduce((prevValue, currValue) => {
      if (
        currValue.status === SUBSCRIPTION_PAYMENT_STATUS.PENDING ||
        currValue.status === SUBSCRIPTION_PAYMENT_STATUS.CANCELED
      ) {
        return prevValue + currValue.Subscription.priceRon
      }
      return prevValue
    }, 0)
  }

  const renderMenuItem = (userSubscription) => {
    return (
      <Box>
        <IconButton
          onClick={(event) => {
            setAnchorElement(event.currentTarget)
            setSelectedUserSubscription(userSubscription)
          }}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorElement}
          open={selectedUserSubscription && selectedUserSubscription.id === userSubscription.id}
          onClose={() => {
            setAnchorElement(null)
            setSelectedUserSubscription()
          }}>
          <MenuItem disabled>{t('clients.actionsForSub')}</MenuItem>
          <Divider />
          <MenuItem
            style={{ minWidth: 200 }}
            onClick={() => {
              setAnchorElement(null)
              setChangePause(userSubscription)
              setSelectedUserSubscription()
            }}>
            <ListItemIcon>
              <Pause />
            </ListItemIcon>
            <ListItemText>{t('clients.pauseNormal')}</ListItemText>
          </MenuItem>
          <MenuItem
            style={{ minWidth: 200 }}
            onClick={() => {
              setAnchorElement(null)
              setChangeStartEndDate(userSubscription)
              setSelectedUserSubscription()
            }}>
            <ListItemIcon>
              <CalendarMonth />
            </ListItemIcon>
            <ListItemText>{t('clients.editDates')}</ListItemText>
          </MenuItem>
          <MenuItem
            style={{ minWidth: 200 }}
            onClick={() => {
              setAnchorElement(null)
              setChangeTrainer(userSubscription)
              setSelectedUserSubscription()
            }}>
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
            <ListItemText>{t('clients.changeTrainer')}</ListItemText>
          </MenuItem>
          {userSubscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED ? (
            <MenuItem
              onClick={() => {
                setAnchorElement(null)
                setConfirmPayment(userSubscription)
                setSelectedUserSubscription()
              }}>
              <ListItemIcon>
                <Money />
              </ListItemIcon>
              <ListItemText>{t('clients.confirmPay')}</ListItemText>
            </MenuItem>
          ) : null}
          {userSubscription.status == SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED &&
          userSubscription.type == PAYMENT_METHOD.CASH ? (
            <Tooltip
              title={
                userSubscription.isInvoiceGenerated
                  ? t('clients.regenerateInvoice')
                  : t('clients.generateInvoice')
              }>
              <MenuItem
                onClick={() => {
                  setAnchorElement(null)
                  setGenerateInvoice(userSubscription)
                  setSelectedUserSubscription()
                }}>
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText>{t('clients.generateInvoice')}</ListItemText>
              </MenuItem>
            </Tooltip>
          ) : null}
          {userSubscription.limitedActivationTotal ? (
            <MenuItem
              onClick={() => {
                setAnchorElement(null)
                setLimitedActivation(userSubscription)
                setSelectedUserSubscription()
              }}>
              <ListItemIcon>
                <Pin />
              </ListItemIcon>
              <ListItemText>{t('clients.credits')}</ListItemText>
            </MenuItem>
          ) : null}
        </Menu>
      </Box>
    )
  }

  useEffect(() => {
    getUserSubscriptions()
  }, [companyId, page])

  useEffect(() => {
    if (!userId) return
    getUser()
  }, [userId])

  return (
    <>
      {generateInvoice ? (
        <GenerateInvoice
          onCancel={() => setGenerateInvoice()}
          data={generateInvoice}
          onConfirm={() => {
            let subscription = userSubscriptions.find((el) => el.id === generateInvoice.id)
            subscription.isInvoiceGenerated = true

            setUserSubscriptions([...userSubscriptions])
            setGenerateInvoice()
          }}
        />
      ) : null}
      {changeStartEndDate ? (
        <ChangeStartEndDate
          onCancel={() => setChangeStartEndDate()}
          onConfirm={getUserSubscriptions}
          open={!!changeStartEndDate}
          userSubscription={changeStartEndDate}
        />
      ) : null}
      {limitedActivation ? (
        <LimitedActivation
          onCancel={() => setLimitedActivation()}
          onConfirm={getUserSubscriptions}
          open={!!limitedActivation}
          userSubscription={limitedActivation}
        />
      ) : null}
      {confirmPayment ? (
        <ConfirmPayment
          onCancel={() => setConfirmPayment()}
          onConfirm={getUserSubscriptions}
          open={!!changePause}
          userSubscriptionId={confirmPayment.id}
        />
      ) : null}
      {changeTrainer ? (
        <ChangeTrainer
          onCancel={() => setChangeTrainer()}
          onConfirm={getUserSubscriptions}
          open={!!changeTrainer}
          userSubscription={changeTrainer}
        />
      ) : null}
      {changePause ? (
        <ChangePause
          onCancel={() => setChangePause()}
          onConfirm={getUserSubscriptions}
          open={!!changePause}
          userSubscription={changePause}
        />
      ) : null}
      <Dialog
        open={true}
        fullScreen
        TransitionComponent={Transition}
        classes={{
          paper: classes.dialogPaper
        }}>
        {loading ? (
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress />
          </Box>
        ) : null}
        {!loading && user ? (
          <Container maxWidth="xl">
            <Grid container pt={5} pb={5}>
              <Grid item xs={12}>
                <Grid container display="flex" flexDirection="row">
                  <Grid item xs={10}>
                    <Typography variant="h4" fontWeight="bold">
                      {t('clients.clientDetails')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} pt={5}>
                <Grid container display="flex" flexDirection="row">
                  <Grid item xs={12} md={12} lg={4} component={Card} p={4}>
                    <Grid container>
                      <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        <Avatar src={user.avatarPhotoSrc}>
                          {user.firstName.charAt(0)}
                          {user.lastName.charAt(0)}
                        </Avatar>
                        <Typography
                          pl={1}
                          variant="h5"
                          fontWeight={500}
                          color={user.isDeleted ? 'error' : 'primary'}>
                          {user.firstName} {user.lastName}{' '}
                          {user.isDeleted && (
                            <Typography variant="h5" fontWeight={500}>
                              {'(Deleted)'}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        pt={2}
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <Typography variant="p" color={GRAY_TEXT_COLOR}>
                          {t('clients.theUser')} {user.firstName} {user.lastName}{' '}
                          {t('clients.joined')} {moment(user.createdAt).format('DD/MM/YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} pt={2} display="flex" flexDirection="column">
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row'
                          }}>
                          <Typography variant="p" color={GRAY_TEXT_COLOR}>
                            {t('clients.email')}
                          </Typography>
                          <Typography variant="p" fontWeight={'500'} pl={1}>
                            {user.email.includes('.deleted.')
                              ? `${user.email.split('.deleted.')[0]}@${user.email.split('@')[1]}`
                              : user.email}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            paddingTop: 3
                          }}>
                          <Typography variant="p" color={GRAY_TEXT_COLOR}>
                            {t('clients.phone')}
                          </Typography>
                          <Typography variant="p" fontWeight={'500'} pl={1}>
                            {user.phoneNumber?.split('.deleted.')?.[0] || user.phoneNumber}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            paddingTop: 3
                          }}>
                          <Typography variant="p" color={GRAY_TEXT_COLOR}>
                            {t('clients.location')}
                          </Typography>
                          <Typography variant="p" fontWeight={'500'} pl={1}>
                            {user.locationName}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            paddingTop: 3
                          }}>
                          <Typography variant="p" color={GRAY_TEXT_COLOR}>
                            {t('clients.gender')}
                          </Typography>
                          <Typography variant="p" fontWeight={'500'} pl={1}>
                            {user.gender}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4} p={4}>
                    <Typography variant="h5" color="primary" gutterBottom fontWeight={500}>
                      {t('clients.revenue')}
                    </Typography>
                    <Typography variant="p" color={GRAY_TEXT_COLOR}>
                      {t('clients.revenueBy')} {user.firstName} {user.lastName}.{' '}
                      {t('clients.theSumBy')}
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        minHeight: 100
                      }}>
                      <Money fontSize="large" color="primary" />{' '}
                      <Typography pl={0.5} variant="h6" color="primary" fontWeight="bold">
                        {computeRevenue()} {currency}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={4}
                    p={4}
                    border={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                    borderRadius={2}>
                    <Typography variant="h5" color="error" gutterBottom fontWeight={500}>
                      {t('clients.unpaidNormal')}
                    </Typography>
                    <Typography variant="p" color={GRAY_TEXT_COLOR}>
                      {t('clients.theSumOfSub')} {user.firstName} {user.lastName}{' '}
                      {t('clients.hasBooked')}
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        minHeight: 100
                      }}>
                      <Money fontSize="large" color="error" />{' '}
                      <Typography pl={0.5} variant="h6" color="error" fontWeight="bold">
                        {computeLostRevenue()} {currency}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} pt={5}>
                <Table
                  dense
                  headCells={[
                    t('clients.Subscription'),
                    t('clients.CreatedAt'),
                    t('clients.Status'),
                    t('clients.Paid'),
                    t('clients.Price'),
                    t('clients.discountName'),
                    t('clients.availability'),
                    t('clients.Credits'),
                    t('clients.Pause'),
                    t('clients.trainer'),
                    t('clients.Actions')
                  ]}
                  rows={userSubscriptions.map((userSubscription) => {
                    const userSubscriptionStatus = getStatus(userSubscription)

                    return {
                      subscriptions: (
                        <Typography variant="subtitle1" fontWeight="bold">
                          {userSubscription.Subscription.name}
                        </Typography>
                      ),
                      createdAt: (
                        <Typography variant="subtitle1" fontWeight={'500'}>
                          {moment(userSubscription.createdAt).format('DD/MM/YYYY hh:mm')}
                        </Typography>
                      ),
                      status: computeSubscriptionStatus(
                        userSubscriptionStatus,
                        userSubscriptionStatus.isDeleted
                      ),
                      paid: computePaidStatus(
                        userSubscriptionStatus,
                        userSubscription.type,
                        userSubscription.Payment && userSubscription.Payment.paymentOrderId
                          ? true
                          : false
                      ),
                      value: (
                        <Typography variant="subtitle1" fontWeight={'500'}>
                          {numberFomatter(userSubscription.price)}
                        </Typography>
                      ),
                      discount: (
                        <>
                          {userSubscription.Voucher ? (
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                              <Typography fontWeight="bold" color="primary">
                                {userSubscription.Voucher.discount}
                                {userSubscription.Voucher.hasFixedDiscount ? ` ${currency}` : ' %'}
                              </Typography>
                            </Box>
                          ) : (
                            '-'
                          )}
                        </>
                      ),
                      validFrom: (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="subtitle1" fontWeight={'500'}>
                            {moment(userSubscription.validFrom).format('DD/MM/YYYY')}
                          </Typography>
                          <Typography variant="subtitle1" fontWeight={'500'}>
                            {moment(userSubscription.validUntil).utc().format('DD/MM/YYYY')}
                          </Typography>
                        </Box>
                      ),
                      limitedActivation: (
                        <Typography variant="subtitle1" fontWeight={'500'}>
                          {userSubscription.limitedActivationTotal
                            ? `${userSubscription.limitedActivationUsed}/${userSubscription.limitedActivationTotal}`
                            : '-'}
                        </Typography>
                      ),
                      pause: (
                        <Typography variant="subtitle1" fontWeight={'500'}>
                          {userSubscription.Pause
                            ? moment(userSubscription.Pause.startDate).format('DD/MM/YYYY') +
                              ' - ' +
                              moment(userSubscription.Pause.endDate).format('DD/MM/YYYY')
                            : '-'}
                        </Typography>
                      ),
                      trainer: userSubscription.GeneratedTrainer ? (
                        <Tooltip
                          title={`${userSubscription.GeneratedTrainer.firstName} ${userSubscription.GeneratedTrainer.lastName}`}>
                          <Box>
                            {' '}
                            <Avatar src={userSubscription.GeneratedTrainer.avatarPhotoSrc}>
                              {userSubscription.GeneratedTrainer
                                ? `${userSubscription.GeneratedTrainer.firstName[0]} ${userSubscription.GeneratedTrainer.lastName[0]}`
                                : ''}
                            </Avatar>{' '}
                          </Box>
                        </Tooltip>
                      ) : (
                        <Typography variant="subtitle1" fontWeight={'500'}>
                          -
                        </Typography>
                      ),
                      actiuni: <>{renderMenuItem(userSubscription)}</>
                    }
                  })}
                />
              </Grid>
              {userSubscriptionsCount ? (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  pt={1}>
                  <SimplePagination
                    count={Math.ceil(Number(userSubscriptionsCount / PAGE_SIZE))}
                    onPageChange={setPage}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Container>
        ) : null}
      </Dialog>
    </>
  )
}

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Grid,
  Typography,
  Card,
  TextField,
  Button,
  Autocomplete,
  Avatar,
  Box,
  InputAdornment
} from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import ConfirmPayment from './ConfirmPayment/ConfirmPayment'
import { Confirm } from '../../common'

import {
  GRAY_BG_COLOR,
  GRAY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  BLACK_BACKGROUND_COLOR,
  ROLE_USER,
  PRIMARY_COLOR,
  SUBSCRIPTION_PAYMENT_STATUS
} from '../../../utils/constants'
import * as USER_REQUESTS from '../../../api/user'
import * as CHECKIN_REQUESTS from '../../../api/checkin'
import { getStatus } from '../../../utils/helpers/userSubscription'
import { useAuthentication } from '../../../context/auth-context'

const Filters = ({ onCreate }) => {
  const { user } = useAuthentication()
  const { t } = useTranslation()

  const [expired, setExpired] = useState()
  const [notStarted, setNotStarted] = useState()
  const [inPause, setInPause] = useState()
  const [showCheckedIn, setShowCheckedIn] = useState()
  const [showConfirmPayment, setShowConfirmPayment] = useState()
  const [personSubscriptions, setPersonSubscriptions] = useState([])
  const [personEvents, setPersonEvents] = useState([])
  const [createLoading, setCreateLoading] = useState(false)
  const [personOptions, setPersonOptions] = useState([])
  const [personValue, setPersonValue] = useState({
    label: '',
    value: ''
  })
  const [userSubscriptionValue, setUserSubscriptionValue] = useState({
    label: '',
    value: ''
  })
  const [userEventValue, setUserEventvalue] = useState({
    label: '',
    value: ''
  })
  const [personSearch, setPersonSearch] = useState()
  const [personSubscriptionsLoading, setPersonSubscriptionsLoading] = useState(false)
  const [personOptionsLoading, setPersonOptionsLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [keyNumber, setKeyNumber] = useState('')
  const [eventId, setEventId] = useState()
  const [userSubscriptionId, setUserSubscriptionId] = useState()
  const [userId, setUserId] = useState()

  const onCreateCheckInHandler = () => {
    if (!user.companyId) return
    setCreateLoading(true)
    return CHECKIN_REQUESTS.create({
      userId,
      keyNumber,
      description,
      companyId: user.companyId,
      createdByEmployee: true,
      ...(userSubscriptionId ? { userSubscriptionId } : {}),
      ...(eventId ? { eventId } : {})
    })
      .then(() => {
        resetFiltersHandler()
        onCreate()
      })
      .catch(err => {
        if (err && err.response && err.response.data.includes('USER_SUBSCRIPTION_NOT_STARTED')) {
          resetFiltersHandler()
          return setNotStarted({
            userName: personValue && personValue.label ? personValue.label : 'Selected Client',
            userSubscription: userSubscriptionValue ? userSubscriptionValue.label : ''
          })
        }
        if (err && err.response && err.response.data.includes('USER_SUBSCRIPTION_EXPIRED')) {
          resetFiltersHandler()
          return setExpired({
            userName: personValue && personValue.label ? personValue.label : 'Selected Client',
            userSubscription: userSubscriptionValue ? userSubscriptionValue.label : ''
          })
        }
        if (err && err.response && err.response.data.includes('HAS_ACTIVE_CHECKIN')) {
          resetFiltersHandler()
          return setShowCheckedIn({
            userName: personValue && personValue.label ? personValue.label : 'Selected Client',
            userSubscription: userSubscriptionValue ? userSubscriptionValue.label : ''
          })
        }
        if (err && err.response && err.response.data.includes('IN_PAUSE')) {
          resetFiltersHandler()
          return setInPause({
            userName: personValue && personValue.label ? personValue.label : 'Selected Client',
            userSubscription: userSubscriptionValue ? userSubscriptionValue.label : ''
          })
        }
      })
      .finally(() => {
        setCreateLoading(false)
      })
  }

  const getAllUsers = () => {
    setPersonOptionsLoading(true)
    if (!personSearch) {
      setPersonOptions([])
      setPersonOptionsLoading(false)
      return
    }
    return USER_REQUESTS.getAllUsers({
      role: ROLE_USER,
      selfInCompanyId: user.companyId,
      ...(personSearch ? { search: personSearch } : {})
    }).then((response) => {
      if (response.length > 1) {
        setPersonOptions([])
      } else {
        setPersonOptions(response);
      };
      setPersonOptionsLoading(false)
    })
  }

  const resetFiltersHandler = () => {
    setPersonSubscriptions([])
    setPersonOptions([])
    setPersonSearch()
    setDescription('')
    setKeyNumber('')
    setUserId()
    setUserSubscriptionId()
    setPersonValue({
      label: '',
      value: ''
    })
    setEventId()
    setUserEventvalue({
      label: '',
      value: ''
    })
    setUserSubscriptionValue({
      label: '',
      value: ''
    })
  }

  const getAllPersonSubscriptionsAndEvents = () => {
    setPersonSubscriptionsLoading(true)
    return Promise.all([
      USER_REQUESTS.getSubscriptions({
        companyId: user.companyId,
        userId
      }),
      USER_REQUESTS.getUpcomingEvents(user.companyId, userId)
    ]).then(([userSubscriptions, userEvents]) => {
      setPersonSubscriptions(userSubscriptions.filter(subscription => getStatus(subscription).active))
      setPersonEvents(userEvents)
      setPersonSubscriptionsLoading(false)
    })
  }

  useEffect(() => {
    getAllUsers()
  }, [personSearch])

  useEffect(() => {
    getAllPersonSubscriptionsAndEvents()
  }, [user, userId])

  const renderPersonSubscriptionsDropdown = useMemo(() => {
    return (
      <Autocomplete
        options={personSubscriptions.map((el) => ({
          label: el.Subscription.name,
          validUntil: t('checkInFilters.validUntil') + moment(el.validUntil).utc().format('DD-MM-YYYY') + (el.status === SUBSCRIPTION_PAYMENT_STATUS.PENDING ? `, ${t('checkIn.notPayed')}` : ''),
          status: el.status,
          expiredStatus: el.expiredStatus,
          value: el.id
        }))}
        autoHighlight
        disabled={!userId}
        loading={personSubscriptionsLoading}
        renderOption={(props, option) => {
          return (
            <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                <Typography fontWeight={500}>{option.label}</Typography>
                <Typography color={GRAY_TEXT_COLOR}>{option.validUntil}</Typography>
              </Box>
            </Box>
          )
        }}
        filterOptions={(options, state) => options}
        onInputChange={(event) =>
          event &&
          event.target &&
          setUserSubscriptionValue((prev) => ({
            ...prev,
            label: event.target.value
          }))
        }
        onChange={(_, newValue) => {
          if (!newValue) return

          setUserSubscriptionValue(newValue)
          setUserSubscriptionId(newValue.value)

          if (newValue.status === SUBSCRIPTION_PAYMENT_STATUS.PENDING) {
            return setShowConfirmPayment({ userSubscriptionId: newValue.value, option: newValue })
          }
        }}
        inputValue={userSubscriptionValue.label}
        renderInput={(params) => (
          <TextField
            placeholder={t('checkInFilters.subscriptions')}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDown />
                </InputAdornment>
              )
            }}
            inputProps={{
              ...params.inputProps,
              style: { marginLeft: 6, padding: 11.5, color: SECONDARY_TEXT_COLOR, fontWeight: 500 }
            }}
          />
        )}
      />
    )
  }, [personSubscriptions, userSubscriptionValue])

  const renderPersonEventsDropdown = useMemo(() => {
    return (
      <Autocomplete
        options={personEvents.map((el) => ({
          label: el.Event.name,
          validUntil: t('checkInFilters.startsAt') + moment(el.Event.start).utc().format('HH:mm'),
          value: el.Event.id
        }))}
        autoHighlight
        disabled={!userId}
        loading={personSubscriptionsLoading}
        renderOption={(props, option) => {
          return (
            <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                <Typography fontWeight={500}>{option.label}</Typography>
                <Typography color={GRAY_TEXT_COLOR}>{option.validUntil}</Typography>
              </Box>
            </Box>
          )
        }}
        filterOptions={(options, state) => options}
        onInputChange={(event) =>
          event &&
          event.target &&
          setUserEventvalue((prev) => ({
            ...prev,
            label: event.target.value
          }))
        }
        onChange={(_, newValue) => {
          if (!newValue) return

          setUserEventvalue(newValue)
          setEventId(newValue.value)
        }}
        inputValue={userEventValue.label}
        renderInput={(params) => (
          <TextField
            placeholder={t('checkInFilters.selectEvent')}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDown />
                </InputAdornment>
              )
            }}
            inputProps={{
              ...params.inputProps,
              style: { marginLeft: 6, padding: 11.5, color: SECONDARY_TEXT_COLOR, fontWeight: 500 }
            }}
          />
        )}
      />
    )
  }, [personEvents, userEventValue])

  const renderPersonDropdown = useMemo(() => {
    const companyId = user.companyId

    return (
      <Autocomplete
        options={personOptions.map((el) => ({
          label: `${el.firstName} ${el.lastName}`,
          avatar: el.avatarPhotoSrc,
          email: el.email,
          phoneNumber: el.phoneNumber,
          value: el.id,
          firstName: el.firstName,
          lastName: el.lastName,
          belongingCompanyId: el.belongingCompanyId
        }))}
        autoHighlight
        loading={personOptionsLoading}
        renderOption={(props, option) => {
          return (
            <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {option.avatar ? (
                  <Avatar
                    sx={{ height: 40, width: 40 }}
                    src={option.avatar}
                  >{option.firstName.charAt(0)}{option.lastName.charAt(0)}</Avatar>
                ) : (
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: GRAY_BG_COLOR,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 25
                    }}
                  >
                    <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                      {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                <Typography fontWeight={500} color={companyId == option.belongingCompanyId ? PRIMARY_COLOR : null}>{option.label}</Typography>
                <Typography fontWeight={500} color={companyId == option.belongingCompanyId ? PRIMARY_COLOR : null}>{option.email}</Typography>
              </Box>
            </Box>
          )
        }}
        inputValue={personValue.label}
        filterOptions={(options, state) => options}
        onInputChange={(event) => {
          if (!event || !event.target) return

          setPersonSearch(event.target.value)
          setPersonValue((prev) => ({
            ...prev,
            label: event.target.value
          }))
        }}
        onChange={(_, newValue) => {
          if (!newValue) return
          setPersonValue(newValue)
          setUserId(newValue.value)
        }}
        renderInput={(params) => (
          <TextField
            placeholder={t('businessEditProfile.searchTrainer')}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="start">
                  <ArrowDropDown />
                </InputAdornment>
              )
            }}
            inputProps={{
              ...params.inputProps,
              style: { marginLeft: 6, padding: 11.5, color: SECONDARY_TEXT_COLOR, fontWeight: 500 }
            }}
          />
        )}
      />
    )
  }, [personOptions, personValue, user])

  const onChangeHandler = useCallback((event) => {
    if (event.target.name === 'description') {
      setDescription(event.target.value)
    } else if (event.target.name === 'keyNumber') {
      setKeyNumber(event.target.value)
    }
  }, [])

  return (
    <>
      {showConfirmPayment && <ConfirmPayment
        userSubscriptionId={showConfirmPayment.userSubscriptionId}
        onCheckIn={onCreateCheckInHandler}
        onConfirm={() => {
          return getAllPersonSubscriptionsAndEvents()
            .finally(() => {
              setUserSubscriptionValue({ ...showConfirmPayment.option, label: showConfirmPayment.option.label.split(',')[0] })
              setUserSubscriptionId(showConfirmPayment.userSubscriptionId)
              setShowConfirmPayment()
            })
        }}
        onCancel={() => setShowConfirmPayment()}
      />}
      {notStarted && <Confirm
        onlyConfirm
        titleText={t('checkIn.subNotStart')}
        description={`${t('checkIn.theSubscription')} ${notStarted.userSubscription} ${t('checkIn.didNotStart')} ${notStarted.userName}!`}
        confirmButtonColor="error"
        confirmText={t('checkIn.confirm')}
        onCancel={() => {
          setNotStarted()
        }}
        onConfirm={() => setNotStarted()}
      />}
      {expired && <Confirm
        onlyConfirm
        titleText={t('checkIn.subExpired')}
        description={`${t('checkIn.thisSubscription')} ${expired.userSubscription} ${t('checkIn.isExpired')}. ${t('checkIn.theUser')} ${expired.userName} ${t('checkIn.hasToBuy')}`}
        confirmButtonColor="error"
        confirmText={t('checkIn.confirm')}
        onCancel={() => {
          setExpired()
        }}
        onConfirm={() => setExpired()}
      />}
      {inPause && <Confirm
        onlyConfirm
        titleText={t('checkIn.subPause')}
        description={`${t('checkIn.theUser')} ${inPause.userName} ${t('checkIn.hasActivePause')} ${inPause.userSubscription}, ${t('checkIn.canCheckInAfter')}`}
        confirmButtonColor="error"
        confirmText={t('checkIn.confirm')}
        onCancel={() => {
          setInPause()
        }}
        onConfirm={() => setInPause()}
      />}
      {showCheckedIn && <Confirm
        onlyConfirm
        titleText={t('checkIn.already')}
        description={`${t('checkIn.theUser')} ${showCheckedIn.userName} ${t('checkIn.checkInAlready')} ${showCheckedIn.userSubscription}. ${t('checkIn.beforeCloseActiveCheckIn')}`}
        cancelText={t('button.cancel')}
        confirmButtonColor="warning"
        confirmText={t('checkIn.confirm')}
        onCancel={() => {
          setShowCheckedIn()
        }}
        onConfirm={() => setShowCheckedIn()}
      />}
      <Grid container>
        <Grid item xs={12} p={4} component={Card}>
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={12} md={12} lg={9}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    spacing={2}
                    pr={{ xs: 0, md: 0, lg: 2 }}
                  >
                    <Grid item md={12} xs={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom fontWeight={500}>
                        {t('checkInFilters.searchClient')}
                      </Typography>
                      {renderPersonDropdown}
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom fontWeight={500}>
                        {t('checkInFilters.subscriptions')}
                      </Typography>
                      {renderPersonSubscriptionsDropdown}
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                      <Typography variant="subtitle1" gutterBottom fontWeight={500}>
                        {t('checkInFilters.selectEvent')}
                      </Typography>
                      {renderPersonEventsDropdown}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Grid
                    container
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'column', lg: 'row' }}
                  >
                    <Grid item lg={4} xs={12} md={12} pr={{ xs: 0, md: 0, lg: 2 }}>
                      <Typography variant="subtitle1" gutterBottom fontWeight={500}>
                        {t('checkInFilters.extraInfo')}
                      </Typography>
                      <TextField
                        onChange={onChangeHandler}
                        value={description}
                        name="description"
                        fullWidth
                        placeholder={t('checkInFilters.extraInfo')}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={2} pr={{ xs: 0, md: 0, lg: 2 }}>
                      <Typography variant="subtitle1" gutterBottom fontWeight={500}>
                        {t('checkInFilters.key')}
                      </Typography>
                      <TextField
                        onChange={onChangeHandler}
                        value={keyNumber}
                        name="keyNumber"
                        fullWidth
                        placeholder={t('checkInFilters.keyPlaceholder')}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} display="flex">
                      <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-end"
                        pt={{ xs: 2, md: 2, lg: 0 }}
                        spacing={{ xs: 2, md: 2, lg: 1 }}
                        pr={{ xs: 0, md: 0, lg: 2 }}
                      >
                        <Grid item xs={12} md={12} lg={6}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={onCreateCheckInHandler}
                            disabled={!userId || (!userSubscriptionId && !eventId) || createLoading}
                            style={{
                              height: 45,
                              backgroundColor: (!userId || (!userSubscriptionId && !eventId) || createLoading) ? BLACK_BACKGROUND_COLOR : PRIMARY_COLOR,
                              opacity: !userId || (!userSubscriptionId && !eventId) ? 0.7 : 1,
                              display: 'flex',
                              justifyContent: 'center',
                              aligItems: 'center'
                            }}
                          >
                            <Typography color="white" variant="subtitle1" fontWeight="bold">
                              {t('checkInFilters.add')}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Button
                            variant="outlined"
                            onClick={resetFiltersHandler}
                            fullWidth
                            style={{
                              height: 45,
                              borderColor: BLACK_BACKGROUND_COLOR,
                              display: 'flex',
                              justifyContent: 'center',
                              aligItems: 'center'
                            }}
                          >
                            <Typography
                              color={BLACK_BACKGROUND_COLOR}
                              fontWeight="bold"
                              variant="subtitle1"
                            >
                              {t('checkInFilters.reset')}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              pl={2}
              xs={12}
              md={12}
              lg={3}
              pt={{ xs: 5, md: 5, lg: 0 }}
              flexDirection="row"
              display="flex"
              alignItems="center"
            >
              <Typography pr={2} display="flex" alignItems="center" variant="h6">
                {t('checkInFilters.or')}
              </Typography>
              <Button style={{ marginLeft: 6, height: 45 }} variant="contained" fullWidth>
                <Typography color="white" variant="subtitle1" fontWeight="bold">
                  {t('checkInFilters.qr')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Filters

import { Avatar, Box, Link, TableCell, Typography } from '@mui/material'

const UserDetailsCell = ({ userDetails }) => (
  <TableCell sx={styles.tableCell}>
    <Avatar src={userDetails.avatar} />
    <Box sx={styles.box}>
      <Link fontSize={'1rem'} color={userDetails.phone.includes('.deleted.') ? 'error' : 'primary'}>
        {userDetails.name} {userDetails.phone.includes('.deleted.') && <Typography>{'(Deleted)'}</Typography>}
      </Link>
      <Typography fontSize={'1rem'}>
        +{userDetails.phone?.split('.deleted.')?.[0] || userDetails.phone}
      </Typography>
    </Box>
  </TableCell>
)

export default UserDetailsCell

const styles = {
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '6%',
    width: '215px',
    border: 'none !important'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
}

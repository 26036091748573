import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, Autocomplete, Box, Avatar } from '@mui/material'
import { Close } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import { Toggle } from '../../common'

import { useAuthentication } from '../../../context/auth-context'
import {
    GRAY_BG_COLOR,
    GRAY_TEXT_COLOR,
    SECONDARY_TEXT_COLOR,
    ROLE_USER,
    SUBSCRIPTION_PAYMENT_STATUS,
    DATE_FORMAT
} from '../../../utils/constants'
import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as USER_REQUESTS from '../../../api/user'
import * as USER_SUBSCRIPTIONS from '../../../api/userSubscriptions'

const AddClient = ({
    onCancel,
    onConfirm
}) => {
    const theme = useTheme()
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState('')
    const [users, setUsers] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())
    const [paid, setPaid] = useState(true)
    const [limitedActivationAvailable, setLimitedActivationAvailable] = useState()

    const onCreateHandler = () => {
        setLoading(true)
        const data = {
            userId: selectedUser.id,
            subscriptionId: selectedSubscription.id,
            status: paid ? SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED : SUBSCRIPTION_PAYMENT_STATUS.PENDING,
            validFrom: moment(startDate).toISOString(),
            validUntil: moment(endDate).toISOString(),
            limitedActivationUsed: selectedSubscription.limitedActivation - limitedActivationAvailable,
            trainerId: user.id
        }

        return USER_SUBSCRIPTIONS.createAdmin(data)
            .then(() => {
                return onConfirm()
            })
            .finally(() => {
                setLoading(false)
                onCancel()
            })
    }

    const getAllUsers = async (filter) => {
        const query = {}

        if (filter) {
            query.search = filter
        }

        if (!filter) {
            setUsers([])
            return
        }

        return USER_REQUESTS.getAllUsers({ ...query, role: ROLE_USER, deleted: false }).then((response) => {
            if (response.length > 1) {
                return setUsers([]);
            }
            setUsers(response)
        })

    }

    const getTrainerSubscriptions = () => {
        return TRAINER_REQUESTS.getTrainerSubscriptions().then((response) => {
            setSubscriptions(response.filter(subscription => !subscription.fromCompany))
        })
    }

    useEffect(() => {
        getTrainerSubscriptions()
    }, [])

    useEffect(() => {
        getAllUsers(filter)
    }, [filter])

    return (
        <Dialog open maxWidth="sm" fullScreen={matchesTablet} fullWidth PaperProps={{ ...(matchesTablet ? { sx: { borderRadius: 0 } } : {}) }}>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.addSub')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Grid item xs={12}>
                        <Box pb={0.5}>
                            <Typography variant="p" fontWeight={500} >
                                {t('employees.selectUser')}
                            </Typography>
                        </Box>
                        <Autocomplete
                            options={users.map((el) => ({
                                label: `${el.firstName} ${el.lastName}`,
                                avatar: el.avatarPhotoSrc,
                                email: el.email,
                                value: el.id,
                                id: el.id,
                                firstName: el.firstName,
                                lastName: el.lastName
                            }))}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            autoHighlight
                            value={selectedUser}
                            noOptionsText={t('employees.searchLocation')}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        {...props}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        key={option.value}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {option.avatar ? (
                                                <Avatar style={{ width: 50, height: 50 }} src={option.avatar} />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                        backgroundColor: GRAY_BG_COLOR,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 25
                                                    }}
                                                >
                                                    <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                        {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                            <Typography fontWeight={500}>{option.label}</Typography>
                                            <Typography color={GRAY_TEXT_COLOR}>{option.email}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }}
                            filterOptions={(options, state) => options}
                            onInputChange={(event) => {
                                setFilter(event.target.value)
                            }}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setSelectedUser({ ...newValue })
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder={'Search person'}
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            marginLeft: 6,
                                            padding: 11.5,
                                            color: SECONDARY_TEXT_COLOR,
                                            fontWeight: 500
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <Box pb={0.5}>
                            <Typography variant="p" fontWeight={500} >
                                {t('clients.selectSub')}
                            </Typography>
                        </Box>
                        <Autocomplete
                            options={subscriptions.map((el) => ({
                                label: el.name,
                                id: el.id,
                                duration: el.duration,
                                limitedActivation: el.inHouseCredits
                            }))}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            autoHighlight
                            noOptionsText={t('employees.searchLocation')}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        {...props}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        key={option.value}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                            <Typography fontWeight={500}>{option.label}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setEndDate(moment(startDate, DATE_FORMAT).add(Number(newValue.duration || 0), 'days'))
                                    setLimitedActivationAvailable(newValue.limitedActivation)
                                    setSelectedSubscription(newValue)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder={t('clients.searchSub')}
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            marginLeft: 6,
                                            padding: 11.5,
                                            color: SECONDARY_TEXT_COLOR,
                                            fontWeight: 500
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    {selectedSubscription && selectedSubscription.limitedActivation ? (
                        <Grid item pt={2} xs={12} display="flex" flexDirection="row">
                            <Grid item xs={6}>
                                <Box pb={0.5}>
                                    <Typography variant="p" fontWeight={500} >
                                        {t('clients.selectLimitedActivation')}
                                    </Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    value={limitedActivationAvailable}
                                    onChange={(event) => setLimitedActivationAvailable(Number(event.target.value))}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} pt={2} display="flex" flexDirection="row">
                        <Grid item xs={6}>
                            <Box pb={0.5}>
                                <Typography variant="p" fontWeight={500} >
                                    {t('clients.startDate')}
                                </Typography>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="DD.MM.YYYY"
                                    value={startDate}
                                    minDate={moment()}
                                    onChange={(newValue) => {
                                        setStartDate(moment(newValue))
                                        if (selectedSubscription) {
                                            setEndDate(moment(newValue).add(Number(selectedSubscription.duration || 0), 'days'))
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} pl={1}>
                            <Box pb={0.5}>
                                <Typography variant="p" fontWeight={500} >
                                    {t('clients.endDate')}
                                </Typography>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="DD.MM.YYYY"
                                    value={endDate}
                                    minDate={moment()}
                                    onChange={(newValue) => {
                                        setEndDate(moment(newValue))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <Toggle
                            value={paid}
                            label={t('clients.paid')}
                            onChange={setPaid}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    pt={2}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pl={2}
                    pr={2}
                >
                    <Button
                        variant="outlined"
                        onClick={onCancel}
                        disabled={loading}
                        style={{
                            color: SECONDARY_TEXT_COLOR,
                            borderColor: SECONDARY_TEXT_COLOR,
                            padding: '15px 40px'
                        }}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onCreateHandler}
                        disabled={!selectedUser || !selectedSubscription || loading}
                        style={{ padding: '15px 40px' }}>
                        {t('common.create')}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default AddClient

import React, { useState, useEffect } from 'react'
import { Dialog, Container, Slide, Grid, Typography, IconButton, Box, CircularProgress, Card, Avatar, Divider, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close, Pause, LocalAtm, Check, MoreVert, Pin, CalendarMonth } from '@mui/icons-material'
import moment from 'moment'

import { Table } from '../../common'
import ChangePause from './ChangePause'
import ConfirmPayment from './ConfirmPayment'
import LimitedActivation from './LimitedActivation'
import ChangeStartEndDate from './ChangeStartEndDate'

import * as USER_REQUESTS from '../../../api/user'
import { GRAY_TEXT_COLOR, GREEN_COLOR, ORANGE_COLOR, RED_CHIP_COLOR, SUBSCRIPTION_PAYMENT_STATUS, LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'
import { numberFomatter } from '../../../utils/helpers/numberFomatter'
import { getStatus } from '../../../utils/helpers/userSubscription'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    dialogPaper: {
        borderRadius: 0
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default ({ onClose, userId, trainerId, currency }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [changeStartEndDate, setChangeStartEndDate] = useState()
    const [limitedActivation, setLimitedActivation] = useState()
    const [confirmPayment, setConfirmPayment] = useState()
    const [changePause, setChangePause] = useState()
    const [anchorElement, setAnchorElement] = useState(null)
    const [selectedUserSubscription, setSelectedUserSubscription] = useState()
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    const [userSubscriptions, setUserSubscriptions] = useState([])


    const computeSubscriptionStatus = status => {
        if (status.paused) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Pause style={{ color: ORANGE_COLOR }} />
                <Typography fontWeight='bold' color={ORANGE_COLOR}>
                    {t('clients.PAUSED')}
                </Typography>
            </Box>
        }

        if (status.expired) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.EXPIRED')}
                </Typography>
            </Box>
        }

        if (status.inactive) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.INACTIVE')}
                </Typography>
            </Box>
        }

        return (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Check color="success" />
                <Typography fontWeight='bold' color={GREEN_COLOR}>
                    {t('clients.ACTIVE')}
                </Typography>
            </Box>

        )
    }

    const computePaidStatus = status => {
        if (status.paid) {
            return (
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <LocalAtm color="success" />
                    <Typography display="flex" fontWeight={500} color={GREEN_COLOR} pl={1}>
                        {t('clients.yes')}
                    </Typography>
                </Box>
            )
        }

        return (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <LocalAtm color="error" />
                <Typography display="flex" fontWeight={500} color={RED_CHIP_COLOR} pl={1}>
                    {t('clients.NO')}
                </Typography>
            </Box>
        )
    }

    const getUser = () => {
        return USER_REQUESTS.getById(userId)
            .then(result => {
                setUser(result)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getUserSubscriptions = () => {
        return USER_REQUESTS.getSubscriptions({ trainerId, userId })
            .then(result => {
                setUserSubscriptions(result)
            })
    }

    const computeRevenue = () => {
        if (!userSubscriptions.length) return 0
        return userSubscriptions.reduce((prevValue, currValue) => {
            if (currValue.status === SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED) {
                return prevValue + currValue.Subscription.priceRon
            }
            return prevValue
        }, 0)
    }

    const computeLostRevenue = () => {
        if (!userSubscriptions.length) return 0
        return userSubscriptions.reduce((prevValue, currValue) => {
            if ((currValue.status === SUBSCRIPTION_PAYMENT_STATUS.PENDING) || (currValue.status === SUBSCRIPTION_PAYMENT_STATUS.CANCELED)) {
                return prevValue + currValue.Subscription.priceRon
            }
            return prevValue
        }, 0)
    }

    const renderMenuItem = userSubscription => {

        return (
            <Box>
                <IconButton onClick={event => {
                    setAnchorElement(event.currentTarget);
                    setSelectedUserSubscription(userSubscription)
                }}>
                    <MoreVert />
                </IconButton>
                <Menu
                    anchorEl={anchorElement}
                    open={selectedUserSubscription && selectedUserSubscription.id === userSubscription.id}
                    onClose={() => {
                        setAnchorElement(null)
                        setSelectedUserSubscription()
                    }}
                >
                    <MenuItem disabled>
                        {t('clients.actionsForSub')}
                    </MenuItem>
                    <Divider />
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangePause(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Pause />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.pauseNormal')}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangeStartEndDate(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <CalendarMonth />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.editDates')}
                        </ListItemText>
                    </MenuItem>
                    {userSubscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setConfirmPayment(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <LocalAtm />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.confirmPay')}
                        </ListItemText>
                    </MenuItem> : null}
                    {userSubscription.limitedActivationTotal ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setLimitedActivation(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Pin />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.credits')}
                        </ListItemText>
                    </MenuItem> : null}
                </Menu>
            </Box>
        )
    }

    useEffect(() => {
        if (!userId) return
        getUser()
        getUserSubscriptions()
    }, [userId, trainerId])

    return (
        <>
            {changeStartEndDate ? <ChangeStartEndDate
                onCancel={() => setChangeStartEndDate()}
                onConfirm={getUserSubscriptions}
                open={!!changeStartEndDate}
                userSubscription={changeStartEndDate}
            /> : null}
            {limitedActivation ? <LimitedActivation
                onCancel={() => setLimitedActivation()}
                onConfirm={getUserSubscriptions}
                open={!!limitedActivation}
                userSubscription={limitedActivation}
            /> : null}
            {confirmPayment ? <ConfirmPayment
                onCancel={() => setConfirmPayment()}
                onConfirm={getUserSubscriptions}
                open={!!changePause}
                userSubscriptionId={confirmPayment.id}
            /> : null}
            {changePause ? <ChangePause
                onCancel={() => setChangePause()}
                onConfirm={getUserSubscriptions}
                open={!!changePause}
                userSubscription={changePause}
            /> : null}
            <Dialog
                open={true}
                fullScreen
                TransitionComponent={Transition}
                classes={{
                    paper: classes.dialogPaper
                }}>
                {loading ? <Box style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box> : null}
                {!loading && user ? <Container maxWidth="xl">
                    <Grid container pt={5} pb={5}>
                        <Grid item xs={12}>
                            <Grid container display="flex" flexDirection="row">
                                <Grid item xs={10}>
                                    <Typography variant="h4" fontWeight='bold'>
                                        {t('clients.clientDetails')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                                    <IconButton onClick={onClose}>
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} pt={5}>
                            <Grid container display="flex" flexDirection="row">
                                <Grid item xs={12} md={12} lg={4} component={Card} p={4}>
                                    <Grid container>
                                        <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                                            <Avatar src={user.avatarPhotoSrc}>
                                                {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                            </Avatar>
                                            <Typography
                                                pl={1}
                                                variant="h5"
                                                fontWeight={500}
                                                color={user.isDeleted ? 'error' : 'primary'}
                                            >
                                                {user.firstName} {user.lastName}
                                                {user.isDeleted && (
                                                    <Typography variant="h5" fontWeight={500}>
                                                      {'(Deleted)'}
                                                    </Typography>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} pt={2} display="flex" flexDirection="row" alignItems="center">
                                            <Typography variant="p" color={GRAY_TEXT_COLOR}>
                                                {t('clients.theUser')} {user.firstName} {user.lastName} {t('clients.joined')} {moment(user.createdAt).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} pt={2} display="flex" flexDirection="column" >
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                <Typography variant="p" color={GRAY_TEXT_COLOR}>
                                                    {t('clients.email')}
                                                </Typography>
                                                <Typography variant="p" fontWeight={"500"} pl={1}>
                                                    {user.email.includes('.deleted.')
                                                    ? `${user.email.split('.deleted.')[0]}@${user.email.split('@')[1]}`
                                                    : user.email}
                                                </Typography>
                                            </Box>
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: 3 }}>
                                                <Typography variant="p" color={GRAY_TEXT_COLOR}>
                                                    {t('clients.phone')}
                                                </Typography>
                                                <Typography variant="p" fontWeight={"500"} pl={1}>
                                                    {user.phoneNumber?.split('.deleted.')?.[0] || user.phoneNumber}
                                                </Typography>
                                            </Box>
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: 3 }}>
                                                <Typography variant="p" color={GRAY_TEXT_COLOR}>
                                                    {t('clients.location')}
                                                </Typography>
                                                <Typography variant="p" fontWeight={"500"} pl={1}>
                                                    {user.locationName}
                                                </Typography>
                                            </Box>
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: 3 }}>
                                                <Typography variant="p" color={GRAY_TEXT_COLOR}>
                                                    {t('clients.gender')}
                                                </Typography>
                                                <Typography variant="p" fontWeight={"500"} pl={1}>
                                                    {user.gender}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={4} p={4}>
                                    <Typography variant="h5" color='primary' gutterBottom fontWeight={500}>Revenue</Typography>
                                    <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('clients.revenueBy')} {user.firstName} {user.lastName}. {t('clients.theSumBy')}</Typography>
                                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', minHeight: 100 }}>
                                        <LocalAtm fontSize="large" color="primary" /> <Typography pl={0.5} variant="h6" color="primary" fontWeight="bold">{computeRevenue()} {currency}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={12} md={12} lg={4} p={4} border={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`} borderRadius={2}>
                                    <Typography variant="h5" color='error' gutterBottom fontWeight={500}>{t('clients.unpaidNormal')}</Typography>
                                    <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('clients.theSumOfSub')} {user.firstName} {user.lastName} {t('clients.hasBooked')}</Typography>
                                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', minHeight: 100 }}>
                                        <LocalAtm fontSize="large" color="error" /> <Typography pl={0.5} variant="h6" color="error" fontWeight="bold">{computeLostRevenue()} {currency}</Typography>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} pt={5}>
                            <Table
                                dense
                                headCells={[
                                    t('clients.Subscription'),
                                    t('clients.CreatedAt'),
                                    t('clients.Status'),
                                    t('clients.Paid'),
                                    t('clients.Price'),
                                    t('clients.ValidFrom'),
                                    t('clients.ValidUntil'),
                                    t('clients.Credits'),
                                    t('clients.Pause'),
                                    t('clients.Actions'),
                                ]}
                                rows={userSubscriptions.map((userSubscription) => {
                                    const userSubscriptionStatus = getStatus(userSubscription)

                                    return {
                                        subscriptions: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight='bold'
                                            >
                                                {userSubscription.Subscription.name}
                                            </Typography>
                                        ),
                                        createdAt: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={'500'}
                                            >
                                                {moment(userSubscription.createdAt).format('DD/MM/YYYY hh:mm')}
                                            </Typography>
                                        ),
                                        status: computeSubscriptionStatus(userSubscriptionStatus),
                                        paid: computePaidStatus(userSubscriptionStatus),
                                        value: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={'500'}
                                            >
                                                {numberFomatter(userSubscription.price)}
                                            </Typography>
                                        ),
                                        validFrom: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={'500'}
                                            >
                                                {moment(userSubscription.validFrom).format('DD/MM/YYYY')}
                                            </Typography>
                                        ),
                                        validUntil: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={'500'}
                                            >
                                                {moment(userSubscription.validUntil).format('DD/MM/YYYY')}
                                            </Typography>
                                        ),
                                        limitedActivation: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={'500'}
                                            >
                                                {userSubscription.limitedActivationTotal ? `${userSubscription.limitedActivationUsed}/${userSubscription.limitedActivationTotal}` : '-'}
                                            </Typography>
                                        ),
                                        pause: (
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={'500'}
                                            >
                                                {userSubscription.Pause ? moment(userSubscription.Pause.startDate).format('DD/MM/YYYY') + ' - ' + moment(userSubscription.Pause.endDate).format('DD/MM/YYYY') : '-'}
                                            </Typography>
                                        ),
                                        actiuni: <>{renderMenuItem(userSubscription)}</>
                                    }
                                })}
                            />
                        </Grid>
                    </Grid>
                </Container> : null}
            </Dialog>
        </>
    )
}
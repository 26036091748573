import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Tooltip,
  Typography,
  Button,
  IconButton,
  TextField,
  Checkbox,
  Autocomplete,
  InputAdornment,
  Chip
} from '@mui/material'
import {
  AddCircle,
  ArrowLeft,
  CheckCircle,
  Delete,
  KeyboardArrowLeft,
  ManageAccounts
} from '@mui/icons-material'
import moment from 'moment-timezone'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import { uniq } from 'lodash'

import {
  CARD_TEXT_COLOR,
  CHIP_BLUE_COLOR,
  DEFAULT_MAX_LENGTH,
  ERROR_COLOR,
  GRAY_BG_COLOR,
  LIGHT_PRIMARY_COLOR,
  ORANGE_COLOR,
  PRIMARY_COLOR,
  RED_CHIP_COLOR,
  ROLE_TRAINER,
  ROLE_USER,
  SECONDARY_TEXT_COLOR,
  WHITE_COLOR
} from '../../../utils/constants'
import { InputGenerator, Toggle } from '../../common'
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as USER_SUBSCRIPTIONS_REQUESTS from '../../../api/userSubscriptions'
import * as CALENDAR_REQUESTS from '../../../api/calendar'
import * as USER_REQUESTS from '../../../api/user'
import * as COMPANY_REQUESTS from '../../../api/company'
import { useAuthentication } from '../../../context/auth-context'
import { getStatus } from '../../../utils/helpers/userSubscription'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

const CalendarServiceEdit = () => {
  const mappedManage = {
    participants: 'Participants',
    waitingList: 'Waiting List',
    trainers: 'Trainers'
  }

  const { user } = useAuthentication()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const currency = useLocation()?.state?.currency || 'RON'
  const [searchParams, setSearchParams] = useSearchParams()

  const [costValues, setCostValues] = useState([{ value: 'CREDITS', label: t('calendar.credits') }])
  const [disabledInputs, setDisabledInputs] = useState([])
  const [filter, setFilter] = useState('')
  const [users, setUsers] = useState([])
  const [service, setService] = useState()
  const [valueRon, setValueRon] = useState()
  const [book, setBook] = useState()
  const [cancel, setCancel] = useState()
  const [valueCredits, setValueCredits] = useState()
  const [location, setLocation] = useState()
  const [locationLat, setLocationLat] = useState()
  const [locationLng, setLocationLng] = useState()
  const [serviceName, setServiceName] = useState()
  const [cost, setCost] = useState()
  const [maxParticipants, setMaxParticipants] = useState()
  const [access, setAccess] = useState()
  const [tags, setTags] = useState()
  const [subscriptions, setSubscriptions] = useState()
  const [payment, setPayment] = useState()
  const [withWaitingList, setWithWaitingList] = useState()
  const [maxWaitingList, setMaxWaitingList] = useState()
  const [availability, setAvailability] = useState()
  const [editing, setEditing] = useState(false)
  const [day, setDay] = useState()
  const [selectedSlot, setSelectedSlot] = useState()
  const [manage, setManage] = useState()
  const [loaded, setLoaded] = useState(false)
  const [trainers, setTrainers] = useState([])
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [userSubscriptionsLoaded, setUserSubscriptionsLoaded] = useState(false)
  const [companySubscriptions, setCompanySubscriptions] = useState([])
  const [tag, setTag] = useState()
  const [enterPressed, setEnterPressed] = useState(false)
  const [loading, setLoading] = useState(false)

  const findSelectedSlot = (slotId, lookInto) => {
    const slotPosition = (lookInto || service).slots.findIndex(
      (el) => Number(el.index) === Number(slotId)
    )
    return (lookInto || service).slots[slotPosition]
  }

  useEffect(() => {
    if (searchParams && searchParams.get('selectedSlot') && service) {
      setSelectedSlot(findSelectedSlot(searchParams.get('selectedSlot')))
    }
    if (searchParams && searchParams.get('day')) {
      setDay(moment(searchParams.get('day')))
    }
  }, [searchParams, service])

  const handleDisabled = useCallback(
    (input, value) => {
      let disabledValues = [...disabledInputs]
      if (input === 'access') {
        if (!value.includes('SUBSCRIPTION')) {
          disabledValues = disabledValues.concat('subscription')
        } else {
          disabledValues = disabledValues.filter((el) => el !== 'subscription')
        }
      }
      if (input === 'cost') {
        if (!value.includes(currency)) {
          disabledValues = disabledValues.concat('valueRon')
        } else {
          disabledValues = disabledValues.filter((el) => el !== 'valueRon')
        }

        if (!value.includes('CREDITS')) {
          disabledValues = disabledValues.concat('valueCredits')
        } else {
          disabledValues = disabledValues.filter((el) => el !== 'valueCredits')
        }
      }
      if (input === 'payment') {
        if (value.length === 1 && value[0] === 'PUBLIC' && value.length === 0) {
          disabledValues = disabledValues.concat('payment')
        } else {
          disabledValues = disabledValues.filter((el) => el !== 'payment')
        }
      }
      setDisabledInputs(uniq(disabledValues))
    },
    [disabledInputs]
  )

  const handleConditions = useCallback(
    (input, value) => {
      let disabledValues = [...disabledInputs]
      if (input === 'access' && value.length === 0) {
        disabledValues = disabledValues.concat(['cost', 'valueRon', 'valueCredits'])
        setCost([])
        setValueRon(0)
        setValueCredits(0)
      }
      if (input === 'access' && value.includes('SUBSCRIPTION') && !value.includes('PUBLIC')) {
        setCostValues([{ value: 'CREDITS', label: t('calendar.credits') }])
        setCost(['CREDITS'])
        disabledValues = disabledValues.filter((el) => el !== 'cost')
      }
      if (input === 'access' && value.includes('PUBLIC') && !value.includes('SUBSCRIPTION')) {
        setCostValues([{ value: currency, label: currency }])
        setCost([currency])
        disabledValues = disabledValues.filter((el) => el !== 'cost')
      }
      if (input === 'access' && value.includes('PUBLIC') && value.includes('SUBSCRIPTION')) {
        setCostValues([
          { value: currency, label: currency },
          { value: 'CREDITS', label: t('calendar.credits') }
        ])
        setCost([currency])
        disabledValues = disabledValues.filter((el) => el !== 'payment')
      }
      if (input === 'cost' && value.includes(currency)) {
        disabledValues = disabledValues.filter((el) => el !== 'payment')
      }
      if (input === 'cost' && !value.includes(currency)) {
        setValueRon(0)
      }
      if (input === 'cost' && !value.includes('CREDITS')) {
        setValueCredits(0)
      }
      setDisabledInputs(uniq(disabledValues))
    },
    [disabledInputs]
  )


  const handleGetCalendarServiceById = useCallback(async () => {
    try {
      if (!searchParams.get('day')) return
      const serviceResponse = await CALENDAR_REQUESTS.getCalendarServiceById(
        params.ID,
        searchParams.get('day')
      )
      setBook(serviceResponse.book)
      setCancel(serviceResponse.cancel)
      setService(serviceResponse)
      setValueRon(serviceResponse.valueRon)
      setValueCredits(serviceResponse.valueCredits)
      setTags(serviceResponse.tags)
      setLocation(serviceResponse.location)
      setLocationLng(serviceResponse.locationLng)
      setLocationLat(serviceResponse.locationLat)
      setServiceName(serviceResponse.name)
      setMaxParticipants(serviceResponse.maxParticipants)
      setAccess(serviceResponse.access)
      setSubscriptions(serviceResponse.subscriptions.map((el) => el.id))
      setPayment(serviceResponse.paymentMethods)
      setWithWaitingList(serviceResponse.withWaitingList)
      setMaxWaitingList(serviceResponse.maxWaitingList)
      setAvailability(serviceResponse.availability)
      setCost(serviceResponse.costs)
      if (selectedSlot) {
        setSelectedSlot(findSelectedSlot(selectedSlot.index, serviceResponse))
      }
      setLoaded(true)
    } catch (e) {
      console.error(e)
    }
  }, [params, selectedSlot])

  const handleGetBusinessSubscriptions = useCallback(() => {
    COMPANY_REQUESTS.getAllSubscriptions(user.companyId).then((res) => {
      const mappedSubscriptions = res.map((el) => ({
        value: el.id,
        label: el.name
      }))
      setCompanySubscriptions(mappedSubscriptions)
    })
  }, [user])

  const handleGetUserSubscriptions = useCallback(async () => {
    try {
      if (!user) return setUserSubscriptions([])

      const slotFrom = moment(searchParams.get('day'))
        .set('hours', selectedSlot.start.split(':')[0])
        .set('minutes', selectedSlot.start.split(':')[1])
      const subscriptionsResponse = await USER_SUBSCRIPTIONS_REQUESTS.getUserSubscriptionsByCompany(
        user.companyId,
        slotFrom
      )

      setUserSubscriptions(
        subscriptionsResponse.filter((el) => {
          return (
            service.subscriptions.map((s) => s.id).includes(el.Subscription.id) &&
            !getStatus(el, slotFrom).notStarted &&
            (getStatus(el, slotFrom).active || getStatus(el, slotFrom).paymentDeadlineActive)
          )
        })
      )

      setUserSubscriptionsLoaded(true)
    } catch (e) {
      console.error(e)
    }
  }, [user, service, selectedSlot])

  const handleGetUsers = useCallback(async () => {
    try {
      const slotFrom = moment(searchParams.get('day'))
        .set('hours', selectedSlot.start.split(':')[0])
        .set('minutes', selectedSlot.start.split(':')[1])
      const usersResponse = await USER_REQUESTS.getAllUsers({
        role: ROLE_USER,
        search: filter,
        selfInCompanyId: user.companyId,
        date: slotFrom
      })

      if (usersResponse.length > 1) setUsers([])
      else setUsers(
        usersResponse.filter((user) => userSubscriptions.map((us) => us.userId).includes(user.id))
      )
    } catch (e) {
      console.error(e)
    }
  }, [filter, userSubscriptions])

  const handleGetTrainers = useCallback(async () => {
    try {
      const trainersResponse = await COMPANY_REQUESTS.getAllMembers(user.companyId)
      setTrainers(trainersResponse)
    } catch (e) {
      console.error(e)
    }
  }, [filter, user])

  useEffect(() => {
    if (!userSubscriptionsLoaded) return

    handleGetUsers()
  }, [userSubscriptionsLoaded, filter])

  useEffect(() => {
    handleGetBusinessSubscriptions()
    handleGetTrainers()
    handleGetCalendarServiceById()
  }, [params])

  useEffect(() => {
    if (!service || !selectedSlot) return
    handleGetUserSubscriptions()
  }, [service, selectedSlot])

  const handleDeleteService = async () => {
    try {
      setLoading(true)
      await CALENDAR_REQUESTS.deleteCalendarService(params.ID, searchParams.get('day'))
      setLoading(false)
      toast.success(`You have successfully removed ${service.name}!`, { position: 'bottom-right' })
      navigate('/calendar')
    } catch (e) {
      setLoading(false)
      toast.error('Ups! Something went wrong', { position: 'bottom-right' })
    }
  }

  const handleAddParticipant = async (participantId, toWaitingList) => {
    const payload = {}

    if (
      userSubscriptions.filter((el) => el.userId === participantId).length > 0 &&
      service.access.includes('SUBSCRIPTION')
    ) {
      payload['subscriptionId'] = userSubscriptions.filter(
        (el) => el.userId === participantId
      )[0].id
    }

    payload['userId'] = participantId
    payload['slotId'] = selectedSlot.index
    payload['calendarServiceId'] = service.id
    payload['isInWaitingList'] = toWaitingList || false
    payload['byOwner'] = true

    try {
      setLoading(true)
      await CALENDAR_REQUESTS.bookCalendarService(payload)
      toast.success('You have successfully added client to this service!', {
        position: 'bottom-right'
      })
      handleGetCalendarServiceById()
      setFilter('')
      setLoading(false)
    } catch (e) {
      console.error(e)
      toast.error('Ups! Something went wrong', { position: 'bottom-right' })
      setFilter('')
      setLoading(false)
    }
  }

  const handleDeleteParticipant = async (participantId) => {
    const payload = {}

    payload['userId'] = participantId
    payload['slotId'] = selectedSlot.index
    payload['calendarServiceId'] = service.id
    payload['byOwner'] = true

    try {
      setLoading(true)
      await CALENDAR_REQUESTS.unbookCalendarService(payload)
      toast.success('You have successfully removed client from this service!', {
        position: 'bottom-right'
      })
      handleGetCalendarServiceById()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
      toast.error('Ups! Something went wrong', { position: 'bottom-right' })
    }
  }

  const handleAddTrainer = async (trainerId) => {
    const payload = {}

    payload['trainerId'] = trainerId
    payload['calendarServiceId'] = service.id

    try {
      setLoading(true)
      await CALENDAR_REQUESTS.addTrainerToService(payload)
      toast.success('You have successfully added trainer to this service!', {
        position: 'bottom-right'
      })
      handleGetCalendarServiceById()
      setFilter('')
      setLoading(false)
    } catch (e) {
      console.error(e)
      toast.error('Ups! Something went wrong', { position: 'bottom-right' })
      setFilter('')
      setLoading(false)
    }
  }

  const handleDeleteTrainer = async (trainerId) => {
    const payload = {}

    payload['trainerId'] = trainerId
    payload['calendarServiceId'] = service.id

    try {
      setLoading(true)
      await CALENDAR_REQUESTS.removeTrainerFromService(payload)
      toast.success('You have successfully removed trainer from this service!', {
        position: 'bottom-right'
      })
      handleGetCalendarServiceById()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
      toast.error('Ups! Something went wrong', { position: 'bottom-right' })
    }
  }

  const handleEditService = async () => {
    try {
      const payload = {
        valueRon,
        valueCredits,
        location,
        book,
        cancel,
        locationLng,
        locationLat,
        name: serviceName,
        maxParticipants,
        availability,
        access,
        cost,
        tags,
        payment,
        subscriptions,
        withWaitingList,
        maxWaitingList
      }

      setLoading(true)
      await CALENDAR_REQUESTS.editCalendarService(params.ID, payload)
      handleGetCalendarServiceById()
      setEditing(false)
      setLoading(false)
      toast.success(`You have successfully edited ${service.name}!`, { position: 'bottom-right' })
    } catch (e) {
      toast.error('Ups! Something wrong.', { position: 'bottom-right' })
      console.error(e)
    }
  }

  const renderServiceDetails = useCallback(() => {
    return (
      <Grid container display="flex" flexDirection="column">
        <Grid item xs={12}>
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={12}>
              <Grid container display="flex" flexDirection="column">
                <Grid item xs={12}>
                  <Avatar
                    src={service.companyAvatar}
                    sx={{
                      cursor: 'pointer',
                      width: 90,
                      height: 90,
                      border: `2px solid ${WHITE_COLOR}`
                    }}>
                    AA
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" alignItems={'center'}>
                    <Typography
                      sx={{ textDecoration: 'underline', color: PRIMARY_COLOR, cursor: 'pointer' }}
                      onClick={() => navigate(`/${service.companyDomain}`)}
                      pr={0.5}
                      fontWeight={'bold'}
                      variant="h6">
                      {service.companyName}
                    </Typography>
                    <CheckCircle sx={{ fontSize: 20 }} color="primary" />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={'bold'} variant="subtitle2" color={CARD_TEXT_COLOR}>
                    Club
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={'bold'}>
                {service.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1" fontWeight={'bold'} pr={1}>
                  Duration:
                </Typography>
                <Typography variant="subtitle1">{service.duration} min</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1" fontWeight={'bold'} pr={1}>
                  Availability:
                </Typography>
                <Typography variant="subtitle1">{service.access.join(', ')}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2.5}>
          <Grid container display="flex" flexDirection="column">
            <Grid item xs={12} pb={1}>
              <Divider textAlign="left">
                <Typography variant="subtitle1" fontWeight={'bold'} color={CARD_TEXT_COLOR}>
                  ACCESS
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1" fontWeight={'bold'} pr={1}>
                  Subscriptions:
                </Typography>
                <Box display="flex" flexDirection={'row'} flexWrap="wrap">
                  {service.subscriptions.map((el, index) => (
                    <>
                      <Typography
                        sx={{
                          textDecoration: 'underline',
                          color: PRIMARY_COLOR,
                          fontWeight: 500,
                          cursor: 'pointer'
                        }}
                        variant="subtitle1">
                        {el.name}
                        {index === service.subscriptions.length - 1 ? '' : ', '}
                      </Typography>
                      &nbsp;
                    </>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1" fontWeight={'bold'} pr={1}>
                  Cost:
                </Typography>
                <Typography variant="subtitle1"> {service.valueCredits} Credits</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2.5}>
          <Grid container display="flex" flexDirection="column">
            <Grid item xs={12} pb={1}>
              <Divider textAlign="left">
                <Typography variant="subtitle1" fontWeight={'bold'} color={CARD_TEXT_COLOR}>
                  LOCATION
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>
                {service.location}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2.5}>
          <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
              <Divider textAlign="left">
                <Typography variant="subtitle1" fontWeight={'bold'} color={CARD_TEXT_COLOR}>
                  DESCRIPTION
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{parse(service.notes || '')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [service])

  const renderCalendar = useCallback(() => {
    return (
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        boxShadow={' rgba(0, 0, 0, 0.04) 0px 3px 5px;'}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateCalendar
            disablePast
            shouldDisableDate={(date) => {
              return service.availableDates.includes(moment(date).format('YYYY-MM-DD'))
                ? false
                : true
            }}
            value={day}
            onChange={(newValue) =>
              navigate(
                `/calendar/services/${service.id}/edit?day=${moment(newValue).format('MM/DD/YYYY')}`
              )
            }
          />
        </LocalizationProvider>
      </Grid>
    )
  }, [day, service])

  const renderManageParticipants = useCallback(() => {
    if (!selectedSlot) return null
    return (
      <Box display="flex" flexDirection="column" pt={1}>
        <Grid item xs={12} pb={2}>
          <Autocomplete
            options={users
              .filter((el) => !selectedSlot.participants.map((par) => par.id).includes(el.id))
              .map((el) => ({
                label: `${el.firstName} ${el.lastName}`,
                avatar: el.avatarPhotoSrc,
                email: el.email,
                value: el.id,
                firstName: el.firstName,
                lastName: el.lastName
              }))}
            autoHighlight
            placeholder={t('calendar.searchParticipants')}
            renderOption={(props, option) => {
              return (
                <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {option.avatar ? (
                      <img
                        style={{ borderRadius: 25 }}
                        width={50}
                        height={50}
                        src={option.avatar}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          backgroundColor: GRAY_BG_COLOR,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 25
                        }}>
                        <Typography fontWeight="bold" color={WHITE_COLOR}>
                          {option.firstName.charAt(0).toUpperCase()}{' '}
                          {option.lastName.charAt(0).toUpperCase()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                    <Typography fontWeight={500}>{option.label}</Typography>
                    <Typography fontWeight={500}>{option.email}</Typography>
                  </Box>
                </Box>
              )
            }}
            onInputChange={(event) => {
              setFilter(event.target.value)
            }}
            filterOptions={(options, state) => options}
            onChange={(_, newValue) => {
              if (!newValue) return

              return handleAddParticipant(newValue.value)
            }}
            disabled={loading}
            renderInput={(params) => (
              <TextField
                placeholder={t('calendar.searchParticipants')}
                {...params}
                InputProps={{ ...params.InputProps, endAdornment: null }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    marginLeft: 6,
                    padding: 11.5,
                    color: SECONDARY_TEXT_COLOR,
                    fontWeight: 500
                  }
                }}
              />
            )}
          />
        </Grid>
        {selectedSlot.participants.map((participant) => {
          return (
            <Box pr={1} display="flex" flexDirection="row" alignItems="center">
              <Tooltip title={`${participant.firstName} ${participant.lastName}`}>
                <Avatar
                  src={participant.avatarPhotoSrc}
                  sx={{
                    cursor: 'pointer',
                    width: 40,
                    height: 40,
                    border: `2px solid ${WHITE_COLOR}`
                  }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {participant.firstName.charAt(0).toUpperCase()}
                    {participant.lastName.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              </Tooltip>
              <Typography variant="body2" pl={2} fontWeight={500}>
                {participant.firstName} {participant.lastName}
              </Typography>
              <Box ml="auto">
                {/* <Checkbox /> */}
                <IconButton
                  disabled={loading}
                  onClick={() => handleDeleteParticipant(participant.id)}>
                  <Delete sx={{ color: ERROR_COLOR }} />
                </IconButton>
              </Box>
            </Box>
          )
        })}
      </Box>
    )
  }, [selectedSlot, loading, users])

  const renderManageTrainers = () => {
    return (
      <Box display="flex" flexDirection="column" pt={1}>
        <Box pb={2}>
          <Autocomplete
            options={trainers
              .filter(
                (el) =>
                  !service.trainers.map((tr) => tr.id).includes(el.id) &&
                  (el.firstName.includes(filter) || el.lastName.includes(filter))
              )
              .map((el) => ({
                label: `${el.firstName} ${el.lastName}`,
                avatar: el.avatarPhotoSrc,
                email: el.email,
                value: el.id,
                firstName: el.firstName,
                lastName: el.lastName
              }))}
            autoHighlight
            placeholder={t('calendar.searchParticipants')}
            renderOption={(props, option) => {
              return (
                <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {option.avatar ? (
                      <img
                        style={{ borderRadius: 25 }}
                        width={50}
                        height={50}
                        src={option.avatar}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          backgroundColor: GRAY_BG_COLOR,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 25
                        }}>
                        <Typography fontWeight="bold" color={WHITE_COLOR}>
                          {option.firstName.charAt(0).toUpperCase()}{' '}
                          {option.lastName.charAt(0).toUpperCase()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                    <Typography fontWeight={500}>{option.label}</Typography>
                    <Typography fontWeight={500}>{option.email}</Typography>
                  </Box>
                </Box>
              )
            }}
            onInputChange={(event) => {
              setFilter(event.target.value)
            }}
            filterOptions={(options, state) => options}
            onChange={(_, newValue) => {
              if (!newValue) return

              return handleAddTrainer(newValue.value)
            }}
            disabled={loading}
            renderInput={(params) => (
              <TextField
                placeholder={t('calendar.searchParticipants')}
                {...params}
                InputProps={{ ...params.InputProps, endAdornment: null }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    marginLeft: 6,
                    padding: 11.5,
                    color: SECONDARY_TEXT_COLOR,
                    fontWeight: 500
                  }
                }}
              />
            )}
          />
        </Box>
        {service.trainers.map((trainer) => {
          return (
            <Box pr={1} display="flex" flexDirection="row" alignItems="center">
              <Tooltip title={trainer.name}>
                <Avatar
                  src={trainer.avatarPhotoSrc}
                  sx={{
                    cursor: 'pointer',
                    width: 40,
                    height: 40,
                    border: `2px solid ${WHITE_COLOR}`
                  }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {trainer.lastName.charAt(0).toUpperCase() || 'A'}
                    {trainer.firstName.charAt(0).toUpperCase() || 'A'}
                  </Typography>
                </Avatar>
              </Tooltip>
              <Typography variant="body2" pl={2} fontWeight={500}>
                {trainer.name}
              </Typography>
              <Box ml="auto">
                <IconButton disabled={loading}>
                  <Delete
                    sx={{ color: ERROR_COLOR }}
                    onClick={() => handleDeleteTrainer(trainer.id)}
                  />
                </IconButton>
              </Box>
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderManageWaitingList = () => {
    return (
      <Box display="flex" flexDirection="column" pt={1}>
        <Grid item xs={12} pb={2}>
          <Autocomplete
            options={users
              .filter((el) => !selectedSlot.waitingList.map((par) => par.id).includes(el.id))
              .filter((el) => !selectedSlot.participants.map((par) => par.id).includes(el.id))
              .map((el) => ({
                label: `${el.firstName} ${el.lastName}`,
                avatar: el.avatarPhotoSrc,
                email: el.email,
                value: el.id,
                firstName: el.firstName,
                lastName: el.lastName
              }))}
            autoHighlight
            placeholder={t('calendar.searchParticipants')}
            renderOption={(props, option) => {
              return (
                <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {option.avatar ? (
                      <img
                        style={{ borderRadius: 25 }}
                        width={50}
                        height={50}
                        src={option.avatar}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          backgroundColor: GRAY_BG_COLOR,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 25
                        }}>
                        <Typography fontWeight="bold" color={WHITE_COLOR}>
                          {option.firstName.charAt(0).toUpperCase()}{' '}
                          {option.lastName.charAt(0).toUpperCase()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                    <Typography fontWeight={500}>{option.label}</Typography>
                    <Typography fontWeight={500}>{option.email}</Typography>
                  </Box>
                </Box>
              )
            }}
            onInputChange={(event) => {
              setFilter(event.target.value)
            }}
            filterOptions={(options, state) => options}
            onChange={(_, newValue) => {
              if (!newValue) return

              return handleAddParticipant(newValue.value, true)
            }}
            disabled={loading}
            renderInput={(params) => (
              <TextField
                placeholder={t('calendar.searchParticipants')}
                {...params}
                InputProps={{ ...params.InputProps, endAdornment: null }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    marginLeft: 6,
                    padding: 11.5,
                    color: SECONDARY_TEXT_COLOR,
                    fontWeight: 500
                  }
                }}
              />
            )}
          />
        </Grid>
        {selectedSlot.waitingList.map((participant) => {
          return (
            <Box pr={1} display="flex" flexDirection="row" alignItems="center">
              <Tooltip title={`${participant.firstName}${participant.lastName}`}>
                <Avatar
                  src={participant.avatarPhotoSrc}
                  sx={{
                    cursor: 'pointer',
                    width: 40,
                    height: 40,
                    border: `2px solid ${WHITE_COLOR}`
                  }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {participant.firstName.charAt(0).toUpperCase()}
                    {participant.lastName.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              </Tooltip>
              <Typography variant="body2" pl={2} fontWeight={500}>
                {participant.firstName} {participant.lastName}
              </Typography>
              <Box ml="auto">
                <IconButton
                  disabled={loading}
                  onClick={() => handleDeleteParticipant(participant.id)}>
                  <Delete sx={{ color: ERROR_COLOR }} />
                </IconButton>
              </Box>
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderManage = () => {
    return (
      <Grid container display="flex" flexDirection="column">
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" alignItems={'center'}>
            <IconButton onClick={() => setManage()}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography pl={2} fontWeight={'bold'}>
              Manage {mappedManage[manage]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {manage === 'participants' && renderManageParticipants()}
          {manage === 'waitingList' && renderManageWaitingList()}
          {manage === 'trainers' && renderManageTrainers()}
        </Grid>
      </Grid>
    )
  }

  const createToggleInput = useCallback((label, val, setVal, disabled) => {
    return (
      <Grid
        container
        display="flex"
        flexDirection="row"
        sx={{ backgroundColor: LIGHT_PRIMARY_COLOR, borderRadius: '4px' }}
        p={1.3}>
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item ml={'auto'}>
          <Toggle
            disabled={disabled}
            color="primary"
            defaultChecked={val}
            onClick={() => setVal(!val)}
          />
        </Grid>
      </Grid>
    )
  }, [])

  const computeNumberOfParticipants = () => {
    return service.slots
      .map((it) => ({
        participants: it.participants.length,
        waitingList: it.waitingList.length
      }))
      .reduce((prev, cur) => {
        return (prev += cur.participants + cur.waitingList)
      }, 0)
  }

  const computeNumberOfOnlyParticipants = () => {
    return service.slots
      .map((it) => ({
        participants: it.participants.length
      }))
      .reduce((prev, cur) => {
        return (prev += cur.participants)
      }, 0)
  }

  const computeNumberOfWaitingListParticipants = () => {
    return service.slots
      .map((it) => ({
        participants: it.waitingList.length
      }))
      .reduce((prev, cur) => {
        return (prev += cur.participants)
      }, 0)
  }

  const checkMaxParticipantsValueSmallerThanActualParticipants = (val) => {
    let check = false
    service.slots.forEach((slot) => {
      if (check) return
      if (slot.participants.length > val) {
        check = true
      }
    })

    return check
  }

  const checkMaxWaitingListParticipantsValueSmallerThanActualParticipants = (val) => {
    let check = false
    service.slots.forEach((slot) => {
      if (check) return
      if (slot.waitingList.length > val) {
        check = true
      }
    })

    return check
  }

  const computeRenderValue = useCallback((selectedValues, label) => {
    return !selectedValues.length ? (
      <Typography color={'#9CB2CD'}>{t('calendar.selectAValue')}</Typography>
    ) : (
      `${selectedValues.length} ${label} selected`
    )
  }, [])

  const createCustomSelectValues = useCallback((val, setVal) => {
    return (
      <Grid container display="flex" flexDirection="row" pt={2}>
        {val.map((el) => (
          <Grid item>
            <Chip
              sx={{
                backgroundColor: CHIP_BLUE_COLOR,
                '& .MuiChip-deleteIcon': {
                  color: ERROR_COLOR,
                  '&:hover': {
                    color: RED_CHIP_COLOR
                  }
                }
              }}
              label={el}
              onDelete={() => {
                setVal((prevValues) => {
                  let prevValuesCopy = [...prevValues]

                  prevValuesCopy = prevValuesCopy.filter((el) => el !== el)

                  return prevValuesCopy
                })
              }}
            />
          </Grid>
        ))}
      </Grid>
    )
  }, [])

  useEffect(() => {
    if (!enterPressed) return
    handleOnAdornmentClick()
  }, [enterPressed])

  const maximumTagsExceededCondition = useCallback((numberOfTags) => numberOfTags === 3, [])

  const handleOnAdornmentClick = useCallback(
    (directValue) => {
      setTags((prevValues) => {
        let prevValuesCopy = [...prevValues]
        let exceeded = false

        if (maximumTagsExceededCondition(prevValuesCopy.length)) {
          toast.error(t('calendar.maxTags'), { position: 'bottom-right', autoClose: false })
          exceeded = true
        }

        if (!exceeded) {
          prevValuesCopy = [
            ...prevValuesCopy,
            directValue ? directValue.toUpperCase() : tag.toUpperCase()
          ]
        }

        setTag('')

        return prevValuesCopy
      })
      setEnterPressed(false)
    },
    [maximumTagsExceededCondition, tag]
  )

  const renderAvailableSlots = useCallback(() => {
    if (manage) return renderManage()
    if (editing) {
      return (
        <Grid container display="flex" flexDirection="column">
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Typography variant="subtitle1" fontWeight={'bold'} color={CARD_TEXT_COLOR}>
                Edit Section
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} pt={2}>
            <Grid container display="flex" flexDirection="column">
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Service Name'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => setServiceName(e.target.value.toUpperCase())}
                  input={{
                    name: 'serviceName',
                    type: 'text',
                    maxLength: 30,
                    endAdornmentComponent: (
                      <InputAdornment position="start">
                        <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                          30
                        </Typography>
                      </InputAdornment>
                    ),
                    placeholder: 'Service Name',
                    value: serviceName,
                    defaultValue: serviceName
                  }}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Location'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChangeGoogle={async (googleData) => {
                    await geocodeByAddress(googleData.value.description).then((result) => {
                      getLatLng(result[0]).then(({ lat, lng }) => {
                        setLocationLat(String(lat))
                        setLocationLng(String(lng))
                      })
                    })

                    setLocation(googleData.label)
                  }}
                  input={{
                    name: 'location',
                    type: 'google',
                    placeholder: 'Location',
                    value: location,
                    defaultValue: location
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container display="flex" flexDirection="row">
                  <Grid item xs={12} pb={2}>
                    <Grid item pr={0.5} pb={1}>
                      <Typography fontWeight={500} variant="p">
                        {'Value Credits'}
                      </Typography>
                    </Grid>
                    <InputGenerator
                      handleOnChange={(e) => setValueCredits(e.target.value)}
                      input={{
                        name: 'valueCredits',
                        type: 'text',
                        disabled:
                          computeNumberOfOnlyParticipants() > 0 ||
                          disabledInputs.includes('valueCredits'),
                        placeholder: 'Value Credits',
                        value: valueCredits,
                        defaultValue: valueCredits
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Max Participants'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => {
                    if (checkMaxParticipantsValueSmallerThanActualParticipants(e.target.value)) {
                      toast.error(
                        'Number of participants cannot be lower than the current number of participants',
                        { position: 'bottom-right' }
                      )
                      return
                    }
                    setMaxParticipants(e.target.value)
                  }}
                  input={{
                    name: 'maxParticipants',
                    type: 'text',
                    placeholder: 'Max Participants',
                    value: maxParticipants,
                    defaultValue: maxParticipants
                  }}
                />
              </Grid>

              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Access'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => {
                    setAccess(e.target.value)
                    handleDisabled(e.target.name, e.target.value)
                    handleConditions(e.target.name, e.target.value)
                  }}
                  input={{
                    name: 'access',
                    type: 'select',
                    multiple: true,
                    disabled: computeNumberOfOnlyParticipants() > 0,
                    placeholder: 'Access',
                    value: access,
                    values: [
                      { value: 'SUBSCRIPTION', label: t('calendar.subscription'), disabled: false }
                    ]
                  }}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Subscriptions'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => setSubscriptions(e.target.value)}
                  input={{
                    name: 'subscriptions',
                    type: 'select',
                    disabled:
                      computeNumberOfOnlyParticipants() > 0 ||
                      disabledInputs.includes('subscription'),
                    multiple: true,
                    placeholder: 'Subscriptions',
                    renderValue: (selectedValues) =>
                      computeRenderValue(selectedValues, 'Subscriptions'),
                    value: subscriptions,
                    values: companySubscriptions
                  }}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Cost'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => {
                    setCost(e.target.value)
                    handleDisabled(e.target.name, e.target.value)
                    handleConditions(e.target.name, e.target.value)
                  }}
                  input={{
                    name: 'cost',
                    type: 'select',
                    disabled:
                      computeNumberOfOnlyParticipants() > 0 || disabledInputs.includes('cost'),
                    multiple: true,
                    placeholder: 'Cost',
                    value: cost,
                    values: costValues
                  }}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Book'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => setBook(e.target.value)}
                  input={{
                    name: 'book',
                    type: 'number',
                    endAdornmentComponent: (
                      <InputAdornment position="start">
                        <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                          {t('calendar.hours')}
                        </Typography>
                      </InputAdornment>
                    ),
                    value: book
                  }}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <Grid item pr={0.5} pb={1}>
                  <Typography fontWeight={500} variant="p">
                    {'Cancel'}
                  </Typography>
                </Grid>
                <InputGenerator
                  handleOnChange={(e) => setCancel(e.target.value)}
                  input={{
                    name: 'cancel',
                    type: 'number',
                    endAdornmentComponent: (
                      <InputAdornment position="start">
                        <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                          {t('calendar.hours')}
                        </Typography>
                      </InputAdornment>
                    ),
                    value: cancel
                  }}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <InputGenerator
                  handleOnChange={(e) => setTag(e.target.value)}
                  handleEnterPressed={() => setEnterPressed(true)}
                  input={{
                    name: 'tags',
                    label: t('input.label.tags'),
                    infoMessage: t('input.placeholder.tags'),
                    value: tag,
                    values: [],
                    type: 'customSelect',
                    placeholder: '#hiking, crossfit, advanced',
                    endAdornmentComponent: (
                      <InputAdornment onClick={() => handleOnAdornmentClick()} position="start">
                        <AddCircle sx={{ cursor: 'pointer' }} color="primary" />
                      </InputAdornment>
                    )
                  }}
                />
                {createCustomSelectValues(tags, setTags)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={2}>
            <Box display="flex" flexDirection="row" fullWidth>
              <Box flex={1} pr={1}>
                <Button
                  onClick={() => setEditing(false)}
                  variant="outlined"
                  color="inherit"
                  fullWidth>
                  Cancel
                </Button>
              </Box>
              <Box flex={1} pl={1}>
                <Button
                  disabled={loading}
                  onClick={() => {
                    handleEditService()
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )
    }
    if (selectedSlot) {
      return (
        <Grid container display="flex" flexDirection="column">
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
              cursor: 'pointer',
              border: `2px solid ${PRIMARY_COLOR}`
            }}
            p={2}
            mb={2}
            onClick={() => setSelectedSlot()}>
            <Box display="flex" flexDirection="row">
              <Typography variant="subtitle2" fontWeight="bold">
                {selectedSlot.start} - {selectedSlot.end}
              </Typography>
              <Box ml="auto">
                <CheckCircle sx={{ fontSize: 20 }} color="primary" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Typography variant="subtitle1" fontWeight={'bold'} color={CARD_TEXT_COLOR}>
                Participants
              </Typography>
            </Divider>
            {selectedSlot.participants.length > 0 ? (
              <Box display="flex" flexDirection="row" pt={1}>
                {selectedSlot.participants.map((participant) => {
                  return (
                    <Box pr={1} display="flex" flexDirection="row" alignItems={'center'}>
                      <Tooltip title={`${participant.firstName} ${participant.lastName}`}>
                        <Avatar
                          src={participant.avatarPhotoSrc}
                          sx={{
                            cursor: 'pointer',
                            width: 40,
                            height: 40,
                            border: `2px solid ${WHITE_COLOR}`
                          }}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {participant.firstName.charAt(0).toUpperCase()}
                            {participant.lastName.charAt(0).toUpperCase()}
                          </Typography>
                        </Avatar>
                      </Tooltip>
                    </Box>
                  )
                })}
                <Box ml="auto">
                  <IconButton onClick={() => setManage('participants')}>
                    <ManageAccounts sx={{ color: PRIMARY_COLOR }} />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row">
                <Typography pt={1} color="#979797" fontWeight={500} fontSize={17}>
                  No participants found
                </Typography>
                <Box ml="auto">
                  <IconButton onClick={() => setManage('participants')}>
                    <ManageAccounts sx={{ color: PRIMARY_COLOR }} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container display="flex" flexDirection="column">
        {service.slots.map((slot) => {
          return (
            <Grid
              item
              xs={12}
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
                cursor: 'pointer',
                border: '2px solid transparent',
                ':hover': {
                  border: `2px solid ${PRIMARY_COLOR}`
                }
              }}
              p={2}
              mb={2}
              onClick={() => {
                setSelectedSlot(findSelectedSlot(slot.index))
              }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="subtitle2" fontWeight="bold">
                  {slot.start} - {slot.end}
                </Typography>
                <Box ml="auto" display="flex" flexDirection="row" alignItems="center">
                  <Box pr={2} display="flex" flexDirection="row">
                    {slot.participants.map((participant) => (
                      <Avatar
                        src={participant.avatarPhotoSrc}
                        sx={{
                          cursor: 'pointer',
                          width: 25,
                          height: 25,
                          border: `2px solid ${WHITE_COLOR}`
                        }}>
                        <Typography variant="caption" fontWeight="bold">
                          {participant.firstName.charAt(0).toUpperCase()}
                          {participant.lastName.charAt(0).toUpperCase()}
                        </Typography>
                      </Avatar>
                    ))}
                  </Box>
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color={!slot.available ? ERROR_COLOR : PRIMARY_COLOR}>
                    {!slot.available ? 'Full Event' : `${slot.available} slots left`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Grid item xs={12} pt={2}>
            <Divider textAlign="left">
              <Typography variant="subtitle1" fontWeight={'bold'} color={CARD_TEXT_COLOR}>
                Trainers
              </Typography>
            </Divider>
            {service.trainers.length > 0 ? (
              <Box display="flex" flexDirection="row" pt={1}>
                {service.trainers.map((trainer) => {
                  return (
                    <Box pr={1}>
                      <Tooltip title={`${trainer.name}`}>
                        <Avatar
                          src={trainer.avatarPhotoSrc}
                          sx={{
                            cursor: 'pointer',
                            width: 40,
                            height: 40,
                            border: `2px solid ${WHITE_COLOR}`
                          }}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {trainer.firstName.charAt(0).toUpperCase()}
                            {trainer.lastName.charAt(0).toUpperCase()}
                          </Typography>
                        </Avatar>
                      </Tooltip>
                    </Box>
                  )
                })}
                <Box ml="auto">
                  <IconButton onClick={() => setManage('trainers')}>
                    <ManageAccounts sx={{ color: PRIMARY_COLOR }} />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row">
                <Typography pt={1} color="#979797" fontWeight={500} fontSize={17}>
                  There are no trainers associated with this service
                </Typography>
                <Box ml="auto">
                  <IconButton onClick={() => setManage('trainers')}>
                    <ManageAccounts sx={{ color: PRIMARY_COLOR }} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} pt={4}>
          <Box display="flex" flexDirection="row" fullWidth>
            <Box flex={1} pr={1}>
              <Button
                disabled={computeNumberOfParticipants() > 0 || loading}
                onClick={() => handleDeleteService()}
                variant="contained"
                color="error"
                fullWidth>
                Delete
              </Button>
            </Box>
            <Box flex={1} pl={1}>
              <Button
                onClick={() => setEditing(true)}
                variant="contained"
                color="primary"
                fullWidth>
                Edit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }, [
    service,
    costValues,
    manage,
    disabledInputs,
    availability,
    selectedSlot,
    serviceName,
    loading,
    valueRon,
    book,
    cancel,
    valueCredits,
    location,
    cost,
    maxParticipants,
    access,
    payment,
    selectedSlot,
    renderManage
  ])

  if (!loaded) return null

  if (!service) return null

  return (
    <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Grid container pt={6} pb={8} display="flex" flexDirection="column" width="100%">
        <Grid item pl={{ xs: 3 }} xs={12}>
          <Button onClick={() => navigate('/calendar')} variant="outlined">
            <ArrowLeft sx={{ position: 'relative', left: -5 }} />
            {t('service.back')}
          </Button>
        </Grid>
        <Grid item xs={12} pt={5} pl={{ xs: 3, md: 3, lg: 4 }} pr={{ xs: 3, md: 3, lg: 4 }}>
          <Grid container display="flex" flexDirection="row" width="100%">
            <Grid item xs={12} md={12} lg={4}>
              {renderServiceDetails()}
            </Grid>
            <Grid
              item
              pt={{ xs: 2, md: 2, lg: 0 }}
              xs={12}
              md={12}
              lg={4}
              pl={{ xs: 0, md: 0, lg: 3.5 }}
              pr={{ xs: 0, md: 0, lg: 3.5 }}>
              {renderCalendar()}
            </Grid>
            <Grid item pt={{ xs: 2, md: 2, lg: 0 }} xs={12} md={12} lg={4}>
              {renderAvailableSlots()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CalendarServiceEdit

import React, { useMemo, useRef, useState } from 'react';
import {
  TextField,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  Checkbox,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  ERROR_COLOR,
  GRAY_TEXT_COLOR,
  LIGHT_PRIMARY_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
  DEFAULT_MAX_LENGTH,
  BLACK,
} from '../../utils/constants'
import { GOOGLE_API_KEY } from '../../utils/config'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  list: {
    width: 'calc(100% - 10px) !important'
  },
  selectMenu: {
    width: '100% !important'
  }
});

const InputGenerator = ({
  input,
  handleOnChange,
  handleOnChangeDate,
  handleOnChangeGoogle,
  handleOnChangePhone,
  handleEnterPressed,
  error,
  helperText,
  extraLabel
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(null);

  const handleSelectOpen = () => {
    if (inputRef.current) {
      setMenuWidth(inputRef.current.offsetWidth);
    }
  };

  const TextComponent = () => {
    return (
      <Grid container flexDirection="column">
        <Grid pb={1} item display="flex" flexDirection="row" alignItems="center">
          <Typography fontWeight={500} variant="body1">
            {input.label}
          </Typography>
          {input.tooltipProps ? (
            <Tooltip {...input.tooltipProps}>
              <InfoOutlined
                color={input.error ? 'error' : 'primary'}
                onClick={input.onInfoClick}
                fontSize="14px"
              />
            </Tooltip>
          ) : null}
          {input.mandatory ? (
            <Typography variant="span" color={PRIMARY_COLOR}>
              *
            </Typography>
          ) : (
            ''
          )}
        </Grid>
        {extraLabel && (
          <Grid pb={2} item>
            <Typography color={GRAY_TEXT_COLOR} variant="body2">
              {extraLabel}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <TextField
            {...input}
            value={input.value}
            placeholder={input.placeholder}
            fullWidth
            onKeyDown={e => {
              e.key === 'Enter' && handleEnterPressed ? handleEnterPressed() : {}
            }}
            type={input.type === 'number' ? 'number' : 'text'}
            label={null}
            multiline={input.multiline || false}
            rows={input.rows || 1}
            name={input.name}
            onChange={handleOnChange}
            variant="outlined"
            error={error}
            helperText={helperText}
            inputProps={{
              maxLength: input.maxLength || DEFAULT_MAX_LENGTH,
              min: 0
            }}
            InputProps={{
              endAdornment: input.endAdornmentComponent ? input.endAdornmentComponent : null,
              startAdornment: input.startAdornment ? (
                <InputAdornment position="start">
                  <Typography variant="body1" fontWeight={500} style={{ color: BLACK }}>
                    {input.startAdornment || ''}
                  </Typography>
                </InputAdornment>
              ) : null,
              sx: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: BLACK
                }
              }
            }}
          />
        </Grid>
      </Grid>
    )
  }

  const passwordStructure = () => (
    <Grid container display="flex" flexDirection="column">
      <Grid item>
        <Typography fontWeight={500} variant="subtitle2" pt={1} pb={1}>
          {t('helper.yourPasswordMust')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={500} variant="body2" color={WHITE_COLOR}>
          - Have at least 6 characters
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={500} variant="body2" color={WHITE_COLOR}>
          - Not be the same as your e-mail address
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={500} variant="body2" color={WHITE_COLOR}>
          - At least one number
        </Typography>
      </Grid>
      <Grid item pb={2}>
        <Typography fontWeight={500} variant="body2" color={WHITE_COLOR}>
          - Password Match
        </Typography>
      </Grid>
    </Grid>
  )

  const PasswordComponent = () => {
    return (
      <Grid container flexDirection="column">
        <Grid pb={1} item display="flex" flexDirection="row" alignItems="center">
          <Typography fontWeight={500} variant="body1">
            {input.label}
          </Typography>
          {input.name === 'password' && (
            <Tooltip placement="right" title={passwordStructure()}>
              <InfoOutlined
                color={input.error ? 'error' : ''}
                sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                fontSize="14px"
              />
            </Tooltip>
          )}
          {input.mandatory ? (
            <Typography variant="span" color={PRIMARY_COLOR}>
              *
            </Typography>
          ) : (
            ''
          )}
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label={null}
            placeholder={input.placeholder}
            name={input.name}
            onChange={handleOnChange}
            type="password"
            variant="outlined"
            error={error}
            helperText={helperText}
            InputProps={{
              sx: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: BLACK
                }
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const RadioComponent = () => {
    return (
      <FormControl>
        <FormLabel id="label-id">
          <Typography fontWeight={500} variant="body1">
            {input.label}{' '}
            {input.mandatory ? (
              <Typography variant="span" color={PRIMARY_COLOR}>
                *
              </Typography>
            ) : (
              ''
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="label-id"
          name={input.name}
          defaultValue={input.value}
          onChange={handleOnChange}
        >
          {input.values.map((el, index) => {
            return (
              <FormControlLabel key={index} value={el.value} control={<Radio sx={{
                color: BLACK,
                '&.Mui-checked': {
                  color: BLACK,
                },
              }} />} label={el.label} />
            )
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const SelectComponent = () => {
    return (
      <Grid container flexDirection="column">
        <Grid pb={1} item>
          <Grid item display="flex" flexDirection="row" alignItems="center">
            <Typography fontWeight={500} variant="body1">
              {input.label}
            </Typography>
            {input.tooltipProps ? (
              <Tooltip {...input.tooltipProps}>
                <InfoOutlined
                  color={input.error ? 'error' : 'primary'}
                  onClick={input.onInfoClick}
                  sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                  fontSize="14px"
                />
              </Tooltip>
            ) : null}
            {input.mandatory ? (
              <Typography variant="span" color={PRIMARY_COLOR}>
                *
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <Select
              ref={inputRef}
              labelId="select-label"
              id="select-id"
              onChange={handleOnChange}
              defaultValue={input.value}
              placeholder={input.placeholder}
              onOpen={handleSelectOpen}
              {...input}
              MenuProps={{
                PaperProps: {
                  style: {
                    width: menuWidth,
                    ...(input.height && { height: input.height })
                  },
                },
              }}
              label={null}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: BLACK
                }
              }}
            >
              {input.values.map((el, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={el.value}
                    disabled={el.disabled || false}
                    style={{ backgroundColor: 'white', color: 'black', paddingLeft: el.withoutShowing ? 28 : '' }}
                  >
                    {input.multiple && !el.withoutShowing ? <Checkbox checked={input.value.includes(el.value)} /> : null}
                    {el.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const GoogleAutocompleteComponent = () => {
    return (
      <Grid container flexDirection="column">
        <Grid pb={1} item>
          <Typography fontWeight={500} variant="body1">
            {input.label}{' '}
            {input.mandatory ? (
              <Typography variant="span" color={PRIMARY_COLOR}>
                *
              </Typography>
            ) : (
              ''
            )}
          </Typography>
        </Grid>
        <Grid item>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_API_KEY}
            autocompletionRequest={input.type === 'autocomplete' && {
              types: input.name === 'city' ? ['locality'] : ['country'],
              componentRestrictions: 
                input.countryCode ? {
                  country: [input.countryCode]
                } : {}
            }}
            selectProps={{
              isDisabled: input.name === 'city' && !input.countryCode ? true : false,
              formatOptionLabel: input.type === 'autocomplete' ? (value) => value.value.structured_formatting.main_text : undefined,
              onChange: input.type === 'autocomplete' ? async (value) => await handleOnChangeGoogle(input.name, value.value.structured_formatting.main_text, value.value.place_id) : handleOnChangeGoogle,
              placeholder: input.placeholder,
              defaultInputValue: input.defaultValue || input.value || '',
              styles: {
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: LIGHT_PRIMARY_COLOR,
                  padding: 4,
                  borderWidth: 0,
                  boxShadow: state.isFocused ? `0 0 0 1px ${BLACK}` : provided.boxShadow,
                  borderColor: state.isFocused ? BLACK : provided.borderColor,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: GRAY_TEXT_COLOR,
                  fontWeight: 400,
                  fontSize: 16,
                  fontFamily: 'Lato, Medium',
                }),
                singleValue: (provided) => ({
                  ...provided
                }),
                option: (provided, state) => ({
                  ...provided,
                  padding: 14,
                  backgroundColor: state.isSelected ? WHITE_COLOR : '',
                  color: state.isSelected ? PRIMARY_COLOR : '',
                  fontFamily: 'Lato, Medium',
                  '&:hover': {
                    backgroundColor: `${LIGHT_PRIMARY_COLOR} !important`
                  }
                })
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const PhoneComponent = () => {
    return (
      <Grid container flexDirection="column">
        <Grid pb={1} item>
          <Typography fontWeight={500} variant="body1">
            {input.label}{' '}
            {input.mandatory ? (
              <Typography variant="span" color={PRIMARY_COLOR}>
                *
              </Typography>
            ) : (
              ''
            )}
          </Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <PhoneInput

              inputStyle={{
                padding: '13.5px 14px 13.5px 58px',
                backgroundColor: '#F5F9FD',
                color: 'black',
                border: helperText ? `1px solid ${ERROR_COLOR}` : 'none',
                width: '100%'
              }}
              specialLabel=""
              country={'ro'}
              value={input.value}
              onChange={(phone) => handleOnChangePhone(phone, input.name)}
            />
          </FormControl>
        </Grid>
        {helperText && (
          <Grid item>
            <Typography
              ml={'14px'}
              mt={'3px'}
              fontSize={'0.75rem'}
              lineHeight={1.66}
              fontWeight={400}
              color="error"
            >
              {helperText}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const DateComponent = () => {
    return (
      <Grid container flexDirection="column">
        <Grid pb={1} item display="flex" flexDirection="row" alignItems="center">
          <Typography fontWeight={500} variant="body1">
            {input.label}
          </Typography>
          {input.mandatory ? (
            <Typography variant="span" color={PRIMARY_COLOR}>
              *
            </Typography>
          ) : (
            ''
          )}
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              value={input.value || moment()}
              OpenPickerButtonProps={{
                style: {
                  marginRight: 16
                }
              }}
              onChange={(value) => handleOnChangeDate(value, input.name)}
              renderInput={(params) => <TextField {...params} fullWidth InputProps={{
                sx: {
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: BLACK
                  }
                }
              }} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    );
  };

  const renderContentOfData = useMemo(() => {
    if (input.type === 'text' || input.type === 'number') return TextComponent();
    else if (input.type === 'phone') return PhoneComponent();
    else if (input.type === 'password') return PasswordComponent();
    else if (input.type === 'radio') return RadioComponent();
    else if (input.type === 'select') return SelectComponent();
    else if (input.type === 'google' || input.type === 'autocomplete') return GoogleAutocompleteComponent();
    else if (input.type === 'date') return DateComponent();
    return TextComponent();
  }, [input, error, helperText]);

  return <>{renderContentOfData}</>;
};

export default InputGenerator;

import React, { useState, useEffect } from 'react'
import { Card, Grid, Typography, Button, Tooltip, Box, Avatar, TextField, IconButton } from '@mui/material'
import { Delete, Save } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PriorityHigh } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { LIGHT_GRAY_BORDER_COLOR, GRAY_TEXT_COLOR, GREEN_COLOR } from '../../../utils/constants'

const CheckIn = ({
  name,
  time,
  isEvent,
  keyNumber,
  userId,
  subscriptionName,
  phoneNumber,
  checkedOutAt,
  informations,
  onDelete,
  onCheckOut,
  checkedOut,
  limitedActivationTotal,
  limitedActivationUsed,
  noDelete,
  warning,
  disabledCheckOut,
  avatarPhotoUrl,
  onUpdateKeyNumber,
  failed
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [keyNumberInput, setKeyNumberInput] = useState({
    value: '',
    isTouched: false
  })

  useEffect(() => {
    setKeyNumberInput({
      value: keyNumber,
      isTouched: false
    })
  }, [keyNumber])

  const renderTitle = () => {
    if (!warning) return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Avatar src={avatarPhotoUrl} sx={{ borderRadius: 50 }}>{name[0]}</Avatar>
        <Typography ml={0.5} variant="h5" fontWeight={600} pl={1} sx={{ textDecoration: 'underline' }} color={failed ? 'error' : 'primary'}>
          {name}
          {phoneNumber.includes(".deleted.") && <Typography marginBottom={{ sm: "-25px", xs: "-25px", md: "-25px", lg: "-23px" }}>{"(Deleted)"}</Typography>}
        </Typography>
      </Box>

    )

    return (
      <Tooltip arrow title={t('checkIn.noCheckout')}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Avatar sx={{ borderRadius: 50 }} src={avatarPhotoUrl}>{name[0]}</Avatar>
          <Typography ml={0.5} variant="h5" fontWeight={600} color={'error'} sx={{ 
            textDecoration: 'underline' 
            }}>
            {name}
            {phoneNumber.includes(".deleted.") && <Typography marginBottom={{ sm: "-25px", xs: "-25px", md: "-25px", lg: "-23px" }}>{"(Deleted)"}</Typography>}
          </Typography>
          <PriorityHigh fontSize="small" color="error" />
        </Box>
      </Tooltip>
    )
  }

  return (
    <Grid container component={Card}>
      <Grid item xs={12} borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
        <Grid container display="flex" flexDirection="row" alignItems="center">
          <Grid
            item
            xs={12}
            md={12}
            lg={5}
            p={3}
            borderBottom={{ lg: 'none', md: 'none', xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/clients?userId=' + userId)}
          >
            {renderTitle()}
          </Grid>
          <Grid
            item
            xs={5}
            md={4}
            lg={2}
            p={{ xs: 2.6, lg: 3 }}
            borderBottom={{ lg: 'none', md: `none`, xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
            borderLeft={{ xs: 'none', md: 'none', lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="subtitle1" color={GRAY_TEXT_COLOR} fontWeight={500}>
              {t('checkIn.CHECK_IN')}
            </Typography>
            <Typography variant="subtitle1" color={failed ? 'error' : 'primary'} fontWeight={600}>
              {time}
            </Typography>
          </Grid>
          <Grid
            item
            md={8}
            lg={2}
            xs={7}
            p={3}
            borderBottom={{ lg: 'none', md: `none`, xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            borderRight={{ xs: 'none', md: 'none', lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="subtitle2" color={GRAY_TEXT_COLOR} fontWeight={500}>
              {t('checkIn.PHONE_NUMBER')}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600} color={failed ? 'error' : 'primary'}>
              {phoneNumber?.split(".deleted.")?.[0] || phoneNumber}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={3}
            lg={1}
            p={3}
            borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="subtitle2" color={GRAY_TEXT_COLOR} fontWeight={500}>
              {t('checkIn.CREDITS')}
            </Typography>
            <Typography variant="h6" fontWeight={600}>
              {limitedActivationTotal ? `${limitedActivationUsed}/${limitedActivationTotal}` : 'N/A'}
            </Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={9}
            lg={2}
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="subtitle2" color={GRAY_TEXT_COLOR} fontWeight={500}>
              {t('checkIn.KEY_NUMBER')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
              {failed ? null : <>
                <TextField
                  placeholder={'Key Number'}
                  disabled={checkedOut}
                  fullWidth
                  value={keyNumberInput.value}
                  onChange={event => {
                    setKeyNumberInput({
                      isTouched: true,
                      value: event.target.value
                    })
                  }}
                />
                <IconButton disabled={!keyNumberInput.isTouched} color="primary" onClick={() => {
                  setKeyNumberInput(prevValue => ({
                    ...prevValue,
                    isTouched: false
                  }))

                  onUpdateKeyNumber(keyNumberInput.value)
                }}><Save /></IconButton>
              </>}
            </Box>

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container display="flex" flexDirection="row" alignItems="center">
          {failed ? null : <Grid item xs={12} md={12} lg={3} p={3} display="flex" flexDirection="column">
            <Typography variant="subtitle1" color={GRAY_TEXT_COLOR} fontWeight={500}>
              {isEvent ? 'Event' : t('checkIn.SUBSCRIPTION')}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {!failed ? subscriptionName : '-'}
            </Typography>
          </Grid>}
          <Grid item xs={12} md={12} lg={3} p={3} display="flex" flexDirection="column">
            {informations && (
              <>
                <Typography variant="subtitle1" color={GRAY_TEXT_COLOR} fontWeight={500}>
                  {t('checkIn.INFO')}
                </Typography>
                <Typography variant="subtitle1" fontWeight={600} color={failed ? 'error' : 'black'}>
                  {informations}
                </Typography>
              </>
            )}
          </Grid>

          <Grid item md={12} lg={6} xs={12} p={3} display="flex" flexDirection="row">
            <Grid
              container
              display="flex"
              flexDirection="row"
              spacing={2}
              justifyContent="flex-end"
            >
              <Grid item xs={8} md={8} lg={8}>
                {disabledCheckOut || failed ? null : <Button
                  onClick={onCheckOut}
                  disabled={checkedOut}
                  fullWidth
                  variant="outlined"
                  color={!checkedOut ? 'primary' : 'success'}
                >
                  <Typography variant="subtitle1" fontWeight="bold" color={!checkedOut ? 'primary' : GREEN_COLOR}>
                    {checkedOut ? `${t('checkIn.CHECKED_OUT')} ${checkedOutAt ? moment(new Date(checkedOutAt)).format('HH:mm') : ''}` : t('checkIn.CHECK_OUT')}
                  </Typography>
                </Button>}
              </Grid>

              {!noDelete ? <Grid item xs={4} md={4} lg={2} display="flex" flexDirection="row">
                <Button fullWidth variant="outlined" color="error" onClick={onDelete}>
                  <Delete fontSize="medium" color="error" />
                </Button>
              </Grid> : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

CheckIn.propTypes = {
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  keyNumber: PropTypes.string.isRequired,
  subscriptionName: PropTypes.string.isRequired,
  informations: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  credits: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onCheckOut: PropTypes.func.isRequired,
  checkedOut: PropTypes.bool.isRequired
}

export default CheckIn
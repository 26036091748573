import PropTypes from 'prop-types'

import { Link, Grid, Typography, Container, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Favorite } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import { CARD_TEXT_COLOR, PRIMARY_COLOR } from '../../utils/constants'
import {useEffect} from "react";

const Footer = ({ company }) => {
  const { href, name } = company
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
  const year = new Date().getFullYear()

  if(matchesTablet) return null

  return (
    <Container maxWidth="xl">
      <Grid container pb={{ xs: 9, lg: 0 }} pt={2} display="flex" flexDirection="row">
        <Grid item xs={12} lg={6}>
          <Typography
            variant="subtitle2"
            display="flex"
            flexDirection="row"
            alignItems="center"
            color={CARD_TEXT_COLOR}
          >
            2024 &copy; v3.1.9
            <Favorite sx={{ color: PRIMARY_COLOR, marginLeft: 1, marginRight: 1, fontSize: 12 }} />
            {t('helper.madeBy')}
            <Link
              href={href}
              target="_blank"
              style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
            >
              &nbsp;&nbsp;{name}&nbsp;&nbsp;
            </Link>{' '}
            {t('helper.forHealthier')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

Footer.defaultProps = {
  company: { href: 'https://sportin.io/', name: 'SportIn' },
  simple: false
}

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  simple: PropTypes.bool
}

export default Footer

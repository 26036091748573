import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Grid,
  Box,
  Typography,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
  SwipeableDrawer,
  Avatar,
  Divider,
  Badge,
  CircularProgress,
  Tooltip,
  ListItemAvatar,
} from '@mui/material'
import Image from '../../assets/activities.png'
import ImageSelected from '../../assets/selectedActivities.png'
import {
  Payment,
  Logout,
  Computer,
  Settings,
  CalendarMonth,
  AddTask,
  CardMembership,
  SupervisorAccount,
  Equalizer,
  Search,
  Menu as MenuIcon,
  Message,
  Notifications,
  Summarize,
  BarChart,
  Person,
  AssignmentInd,
  Add,
  AccountCircle,
  FitnessCenter,
  ArrowForwardIos,
  SwitchLeft,
  LogoutOutlined,
  Apartment,
  ArrowBackIos,
  Info,
  QrCode2,
  CallSplit,
  RequestQuote,
  Discount,
  Tune, PanoramaFishEye
} from '@mui/icons-material'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useAuthentication } from '../../context/auth-context'
import {
  ROLE_BUSINESS_ADMINISTRATOR_OPTIONS,
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  ROLE_TRAINER_OPTIONS,
  GRAY_BORDER_COLOR,
  ROLE_USER,
  ROLE_USER_OPTIONS,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  BUSINESS_ROLES,
  ROLE_BUSINESS_EMPLOYEE_OPTIONS,
  LIGHT_BLUE_COLOR,
  SUPER_ADMIN,
  ROLE_ORGANIZATION
} from '../../utils/constants'
import { switchRoleObservable } from '../../utils/observables'
import { AvatarNoImage, QrReader } from '../common'
import Notification from './subcomponentsTrainer/Notification'
import { ReactComponent as Logo } from '../../assets/logo-simple.svg'

import RO from '../../assets/RO.png'
import EN from '../../assets/EN.png'

import * as NOTIFICATION_REQUESTS from '../../api/notification'
import * as EMPLOYEE_REQUESTS from '../../api/employees'
import * as USER_REQUESTS from '../../api/user'
import { openStripeBillingPortal } from '../Price/Payments'
import { CHALLENGES_URL } from '../../utils/config'

const useStyles = makeStyles({
  notificationsList: {
    border: `1px solid rgba(0,0,0,0.1)`,
    padding: 0
  }
})

const Navbar = ({ isMenuOpen, handleOpenMenu }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const { user, handleToken, handleLoggedIn, handleUser, token } = useAuthentication()
  const { t, i18n } = useTranslation()
  const matches1656px = useMediaQuery('(min-width:1656px)')

  const [showSimpleMenu, setShowSimpleMenu] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showCompanies, setShowCompanies] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [companiesLoading, setCompaniesLoading] = useState(false)
  const [companiesCount, setCompaniesCount] = useState(0)

  const [anchorElNotifications, setAnchorElNotifications] = useState(null)
  const [openMenuNotifications, setOpenMenuNotifications] = useState(false)

  const [companies, setCompanies] = useState([])
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('')
  const [notifications, setNotifications] = useState([])

  const [showScanQr, setShowScanQr] = useState(false)


  const isSuperAdmin = user && user.role && user.role.toLowerCase() === SUPER_ADMIN.toLowerCase()
  const isOrganization = user && user.role && user.role.toLowerCase() === ROLE_ORGANIZATION.toLowerCase()
  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isUser = user && user.role && user.role.toLowerCase() === ROLE_USER.toLowerCase()
  const isBusinessAdministrator =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const handleClickNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget)
    setOpenMenuNotifications(true)
  }
  const handleCloseMenuNotifications = () => {
    setOpenMenuNotifications(false)
    setAnchorElNotifications(null)
  }

  const onSwitchRole = (companyId) => {
    switchRoleObservable.setSwitchCompanyRole({ companyId, navigate: '/business-profile' })
    localStorage.setItem('companyId', companyId)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenMenu(true)
  }
  const handleCloseMenu = () => {
    setOpenMenu(false)
    setAnchorEl(null)
  }

  const renderCompaniesList = useMemo(() => {
    return (
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {companiesLoading && (
          <MenuItem
            style={{ display: 'flex', justifyContent: 'center', aligItems: 'center', width: 350 }}
          >
            <CircularProgress size="1.5rem" />
          </MenuItem>
        )}

        {!companiesLoading && (
          <MenuItem style={{ width: 350 }} onClick={() => setShowSimpleMenu(true)}>
            <ListItemIcon>
              <ArrowBackIos fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
        )}
        {!companiesLoading && <Divider />}
        {!companiesLoading && (
          <MenuItem style={{ width: 350 }}>
            <ListItemText>Select a Club</ListItemText>
          </MenuItem>
        )}
        {companies.map((el, index) => {
          return (
            <MenuItem
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: user.companyId === el.id ? LIGHT_BLUE_COLOR : undefined,
                width: 350
              }}
              onClick={() => {
                onSwitchRole(el.id)
                handleCloseMenu()
              }}
            >
              <Box style={{ width: '100%' }}>
                <Tooltip arrow title={el.locationName + ' - ' + el.name}>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item display="flex" alignItems="center" pr={1}>
                      {el.avatarPhotoSrc ? (
                        <img
                          src={el.avatarPhotoSrc}
                          width={40}
                          height={40}
                          style={{ borderRadius: '50%' }}
                        />
                      ) : (
                        <AvatarNoImage
                          name={el.name}
                          width={40}
                          height={40}
                          typographyVariant="subtitle1"
                        />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="subtitle1" fontWeight={'bold'} noWrap>
                        {el.name}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" fontWeight={500} noWrap>
                        {el.locationName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Box>
            </MenuItem>
          )
        })}
      </Menu>
    )
  }, [companiesLoading, companies, anchorEl, openMenu, t])

  const renderNotificationList = useMemo(() => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElNotifications}
        open={openMenuNotifications}
        onClose={handleCloseMenuNotifications}
        classes={{
          list: classes.notificationsList
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Grid container display="flex" flexDirection="column" maxWidth={400}>
          {notifications.map((el, index) => {
            return (
              <Grid
                item
                xs={12}
                key={index}
                p={1}
                sx={{
                  borderBottom:
                    index === notifications.length - 1 ? 'none' : `1px solid ${GRAY_BORDER_COLOR}`
                }}
              >
                <Notification
                  handleReadNotification={() => handleReadNotification(el.id)}
                  handleCloseMenuNotifications={handleCloseMenuNotifications}
                  index={index}
                  forTrainer={el.forTrainer}
                  forUser={el && el.Author && el.Author.role === ROLE_USER}
                  avatarSrc={
                    (el.serviceId || (!el.forTrainer && el.Author))
                      ? el.Author.avatarPhotoSrc
                      : el.forTrainer && el && el.Company && el.Company.avatarPhotoSrc
                        ? el.Company.avatarPhotoSrc
                        : el && el.Trainer && el.Trainer.avatarPhotoSrc
                          ? el.Trainer.avatarPhotoSrc
                          : ''
                  }
                  name={
                    el.forTrainer
                      ? el && el.Company && el.Company.name
                        ? el.Company.name
                        : null
                      : null
                  }
                  firstName={
                    (el.serviceId || (!el.forTrainer && el.Author))
                      ? el.Author.firstName
                      : !el.forTrainer
                        ? el && el.Trainer && el.Trainer.firstName
                          ? el.Trainer.firstName
                          : ''
                        : (el.Author ? el.Author.firstName : '')
                  }
                  lastName={
                    (el.serviceId || (!el.forTrainer && el.Author))
                      ? el.Author.lastName
                      : !el.forTrainer
                        ? el && el.Trainer && el.Trainer.lastName
                          ? el.Trainer.lastName
                          : ''
                        : (el.Author ? el.Author.lastName : '')
                  }
                  title={el.title}
                  createdAt={el.createdAt}
                  read={el.read}
                  serviceId={el.serviceId}
                  authorId={el && el.Author ? el.Author.id : null}
                  companyDomain={el && el.Company ? el.Company.domain : null}
                  trainerDomain={el && el.Trainer ? el.Trainer.domain : null}
                  message={el.message}

                />
              </Grid>
            )
          })}
        </Grid>
      </Menu>
    )
  }, [anchorElNotifications, openMenuNotifications, notifications, t])

  useEffect(() => {
    if (!showCompanies && !showSimpleMenu) return

    setCompaniesLoading(true)
    EMPLOYEE_REQUESTS.getClubs(user.id)
      .then((res) => {
        setCompanies(res)
        setCompaniesCount(res.length)
      })
      .finally(() => {
        setCompaniesLoading(false)
      })
  }, [user, showCompanies])

  useEffect(() => {
    if (!isMenuOpen) return

    setCompaniesLoading(true)
    EMPLOYEE_REQUESTS.getClubs(user.id)
      .then((res) => {
        setCompanies(res)
      })
      .finally(() => {
        setCompaniesLoading(false)
      })
  }, [user, isMenuOpen])

  useEffect(() => {
    if (showSimpleMenu) return

    setCompaniesLoading(true)
    EMPLOYEE_REQUESTS.getClubs(user.id)
      .then((res) => {
        setCompanies(res)
      })
      .finally(() => {
        setCompaniesLoading(false)
      })
  }, [user, showSimpleMenu])

  const handleGetNotifications = (isBusinessAdmin, isClubAdmin) => {
    if (isBusinessAdmin || isClubAdmin) {
      NOTIFICATION_REQUESTS.getCompanyNotifications(user.companyId).then(result => {
        setNotifications(result)
      })
    } else if (isTrainer) {
      NOTIFICATION_REQUESTS.getTrainerNotifications().then(setNotifications)
    }
    else {
      NOTIFICATION_REQUESTS.getUserNotifications().then(setNotifications)
    }
  }

  useEffect(() => {
    handleGetNotifications(isBusinessAdministrator, isClubAdministrator)
  }, [isBusinessAdministrator, isClubAdministrator, isTrainer, user])

  const handleIcon = useCallback(
    (name) => {
      if (name === 'dashboard')
        return <Computer fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
      if (name === 'calendar')
        return <CalendarMonth fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
      if (name === 'checkin')
        return <AddTask fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
      if (name === 'membership')
        return (
          <CardMembership fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
        )
      if (name === 'activities')
        return (
          <Avatar style={{ width: 24, height: 24 }} src={location && location.pathname && location.pathname.includes(name) ? ImageSelected : Image} />
        )
      if (name === 'clients')
        return (
          <SupervisorAccount fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
        )
      if (name === 'measurements')
        return <Equalizer fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
      if (name === 'progress')
        return <Equalizer fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
      // if (name === 'explore')
      //   return <Explore fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
      if (name === 'settings')
        return <Settings fontSize={'medium'} color={`${location && location.pathname && location.pathname.includes(name) ? 'primary' : ''}`} />
    },
    [location]
  )

  useEffect(() => {
    if (!user) return

    let optionsToAdd = []
    if (user.role.toLowerCase() === ROLE_USER.toLowerCase()) {
      optionsToAdd = ROLE_USER_OPTIONS.map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name),
        navigate: el.name
      }))
    } else if (user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()) {
      optionsToAdd = ROLE_TRAINER_OPTIONS.map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name),
        navigate: el.name
      }))
    } else if (user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()) {
      optionsToAdd = ROLE_BUSINESS_ADMINISTRATOR_OPTIONS.filter(el => matches1656px ? true : el.name !== 'employees').map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name),
        navigate: el.name
      }))
    } else if (user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()) {
      optionsToAdd = ROLE_BUSINESS_EMPLOYEE_OPTIONS.map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name),
        navigate: el.name
      }))
    }

    setOptions(optionsToAdd)
  }, [user, matches1656px, selected, t])


  const handleProfile = () => {
    if (user.role.toLowerCase() === ROLE_USER.toLowerCase()) {
      handleCloseMenu()
      return navigate('/user-profile')
    } else if (user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()) {
      handleCloseMenu()
      return navigate('/trainer-profile')
    } else if (user.role.toLowerCase() === ROLE_ORGANIZATION.toLowerCase()) {
      handleCloseMenu()
      return navigate('/company-profile')
    } else if (
      user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase() ||
      user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()
    ) {
      handleCloseMenu()
      return navigate('/business-profile')
    }
  }

  const handleLogout = () => {
    handleCloseMenu()
    localStorage.removeItem('token')
    localStorage.removeItem("newLanguage")
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('email')
    localStorage.removeItem('companyId')
    handleUser(null)
    handleLoggedIn(false)
    handleToken('')
    navigate('/')
  }

  const handleReadNotification = (id) => {
    NOTIFICATION_REQUESTS.readNotification(id).then(() => {
      handleGetNotifications(isBusinessAdministrator, isClubAdministrator)
    })
  }

  if (isSuperAdmin) {
    return (
      <Grid xs={12} display="flex" justifyContent="flex-end"><Logout onClick={handleLogout} sx={{ cursor: 'pointer' }} /></Grid>
    )
  }

  return (
    <>
      {showScanQr ? <QrReader
        onCancel={() => setShowScanQr(false)}
      /> : null}
      {isMenuOpen && (
        <Grid display={{ xs: 'flex', lg: 'none' }} height="100%">
          <React.Fragment key={'left'}>
            <SwipeableDrawer
              anchor={'left'}
              open={isMenuOpen}
              onClose={() => handleOpenMenu()}
              onOpen={() => setOpenMenu()}
            >
              <MenuList>
                <MenuItem style={{ minWidth: 300 }}>
                  <ListItemAvatar>
                    {isBusinessAdministrator || isClubAdministrator ? null : (
                      <Avatar src={isOrganization && user.picture ? user.picture : user.avatarPhotoSrc}>
                        {isOrganization && user.given_name ? `${user.given_name.charAt(0)} ${user.family_name.charAt(0)}` : `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`}
                      </Avatar>
                    )}
                    {!isBusinessAdministrator && !isClubAdministrator
                      ? null
                      : (isBusinessAdministrator || isClubAdministrator) && (
                        <Avatar src={user.companyAvatarPhotoSrc}>
                          {user && user.companyName ? user.companyName.charAt(0) : 'C'}
                        </Avatar>
                      )}
                  </ListItemAvatar>
                  <ListItemText style={{ width: '80%' }}>
                    <Box flexDirection="column">
                      <Box>
                        <Typography noWrap variant="p" fontWeight="bold">
                          {user ? user.firstName : ''}&nbsp;{user ? user.lastName : ''}
                        </Typography>
                      </Box>
                      <Box>
                        {isUser ? (
                          <Typography noWrap variant="p" color="primary" fontWeight="bold">
                            {user.credits || 0} credits
                          </Typography>
                        ) : (
                          <Typography variant="p" fontWeight={500} color={GRAY_TEXT_COLOR}>
                            {isTrainer ? 'Trainer' : `${user.companyName}`}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setSelected('profile')
                    navigate(
                      isUser
                        ? '/user-profile'
                        : isBusinessAdministrator || isClubAdministrator
                          ? '/business-profile'
                          : isTrainer
                            ? '/trainer-profile'
                            : '/user-profile'
                    )
                    handleOpenMenu()
                  }}
                >
                  <ListItemIcon>
                    <Person fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.myProfile')}</ListItemText>
                </MenuItem>
                {!isClubAdministrator && <MenuItem
                  onClick={() => {
                    setSelected('settings')
                    navigate('/settings')
                    handleOpenMenu()
                  }}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.settings')}</ListItemText>
                </MenuItem>}
                {user.role === ROLE_BUSINESS_ADMINISTRATOR ? <MenuItem
                  onClick={() => {
                    setSelected('configurations')
                    navigate('/configurations')
                    handleOpenMenu()
                  }}
                >
                  <ListItemIcon>
                    <Tune fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.configurations')}</ListItemText>
                </MenuItem> : null}
                {user.role === ROLE_USER ? <>
                  <Divider />
                  <MenuItem onClick={() => {
                    setShowScanQr(true)
                    handleOpenMenu()
                  }}>
                    <ListItemIcon>
                      <QrCode2 />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.scan')}</ListItemText>
                  </MenuItem>
                </> : null}
                {user.role === ROLE_USER ? <>
                  <Divider />
                  <MenuItem onClick={() => {
                    navigate('/recurring-payments')
                    handleOpenMenu()
                  }}>
                    <ListItemIcon>
                      <RequestQuote />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.recurringPayments')}</ListItemText>
                  </MenuItem>
                </> : null}
                <Divider />
                {options.map((el, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setSelected(el.name)
                        navigate(el.navigate)
                        handleOpenMenu()
                      }}
                      key={index}
                    >
                      <ListItemIcon>{el.icon}</ListItemIcon>
                      <ListItemText>{el.label}</ListItemText>
                    </MenuItem>
                  )
                })}
                {user.role === ROLE_USER ? <MenuItem
                  selected={location && location.pathname && location.pathname.includes('payments')}
                  onClick={() => {
                    navigate('/payments')
                    handleOpenMenu()
                  }}
                >
                  <ListItemIcon>
                    <Payment />
                  </ListItemIcon>
                  <ListItemText>{isUser ? t("navbar.transactions") : t("navbar.payments")}</ListItemText>
                </MenuItem> : null}
                {user.role === ROLE_USER ? <>
                  <Divider />
                  <MenuItem onClick={() => {
                    setShowScanQr(true)
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <QrCode2 />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.scan')}</ListItemText>
                  </MenuItem>
                </> : null}

                {user.role === ROLE_BUSINESS_ADMINISTRATOR || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <>
                  <Divider />
                  <MenuItem onClick={() => {
                    navigate('/vouchers')
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <Discount />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.vouchers')}</ListItemText>
                  </MenuItem>
                </> : null}

                {isBusinessAdministrator || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <MenuItem onClick={() => {
                  navigate("/employees")
                  handleCloseMenu()
                }}>
                  <ListItemIcon>
                    <AssignmentInd />
                  </ListItemIcon>
                  <ListItemText>{t("navbar.option.employees")}</ListItemText>
                </MenuItem> : null}

                {user.role === ROLE_BUSINESS_ADMINISTRATOR ? <>
                  <MenuItem onClick={() => {
                    navigate('/audit-logs')
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <PanoramaFishEye />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.auditLogs')}</ListItemText>
                  </MenuItem>
                </> : null}
                {user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <MenuItem onClick={() => {
                  navigate("/reports")
                  handleOpenMenu()
                }}>
                  <ListItemIcon>
                    <Summarize />
                  </ListItemIcon>
                  <ListItemText>{t("navbar.option.reports")}</ListItemText>
                </MenuItem> : null}

                {isBusinessAdministrator ? <Divider /> : null}

                {isBusinessAdministrator || user.role === ROLE_ORGANIZATION ?
                  <MenuItem onClick={async () => {
                    await openStripeBillingPortal(user.role === ROLE_ORGANIZATION ? 'organization' : 'company');
                    handleCloseMenu();
                  }}>
                    <ListItemIcon>
                      <RequestPageIcon />
                    </ListItemIcon>
                    <ListItemText>{t("navbar.option.billing")}</ListItemText>
                  </MenuItem> : null}

                {isBusinessAdministrator ?
                  <MenuItem onClick={() => {
                    navigate("/price")
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText>{t("navbar.option.price")}</ListItemText>
                  </MenuItem> : null}

                {user.role === ROLE_BUSINESS_ADMINISTRATOR ?
                  <MenuItem onClick={() => {
                    // navigate('/payouts')
                    window.open('https://dashboard.stripe.com/payouts')
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <CallSplit />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.payouts')}</ListItemText>
                  </MenuItem> : null}

                {companies.length ? <Divider /> : null}
                {companies && companies.length ? (
                  <MenuItem>
                    <ListItemText>{t('navbar.clubs')}</ListItemText>
                  </MenuItem>
                ) : null}
                {companies && companies.length && user.role !== ROLE_BUSINESS_ADMINISTRATOR ? (
                  <MenuItem
                    onClick={() => {
                      EMPLOYEE_REQUESTS.switchRoleBack().then((response) => {
                        handleLoggedIn(true)
                        handleToken(response.token)
                        handleUser(response.user)
                        localStorage.removeItem("companyId")
                        localStorage.setItem('token', response.token)
                        navigate('/')
                        handleCloseMenu()
                      })
                    }}
                  >
                    <ListItemIcon>
                      <SwitchLeft />
                    </ListItemIcon>
                    <ListItemText>
                      {t('navbar.switchTo')}{' '}
                      <b>
                        {user.firstName} {user.lastName}
                      </b>
                    </ListItemText>
                  </MenuItem>
                ) : null}
                {isBusinessAdministrator ? (
                  <MenuItem
                    onClick={() => {
                      navigate('/business-create')
                      handleOpenMenu()
                    }}
                  >
                    <ListItemIcon>
                      {' '}
                      <Add />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.createClub')}</ListItemText>
                  </MenuItem>
                ) : null}
                {companies && companies.length
                  ? companies.map((el, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          onSwitchRole(el.id)
                          handleCloseMenu()
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src={el.avatarPhotoSrc}>{el.name.charAt(0)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ wrap: 'noWrap' }}>
                          <Box
                            style={{ display: 'flex', flexDirection: 'column', maxWidth: 250 }}
                          >
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              noWrap
                              variant="body2"
                              color="text.primary"
                            >
                              {el.name}
                            </Typography>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              noWrap
                              variant="body2"
                              color="primary"
                            >
                              {el.locationName}
                            </Typography>
                          </Box>
                        </ListItemText>
                      </MenuItem>
                    )
                  })
                  : null}

                <Divider />
                <MenuItem
                  onClick={() => {
                    window.open(
                      'https://help.sportin.io/en')
                  }}
                >
                  <ListItemIcon>
                    <Info fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.userManual')}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    USER_REQUESTS.updateLanguage(user.id, i18n.language === 'en' ? 'RO' : 'ENG').then(() => {
                      i18n.changeLanguage(i18n.language === 'en' ? 'ro' : 'en')
                      localStorage.setItem("newLanguage", i18n.language === 'en' ? 'en' : 'ro')
                    })
                  }}
                >
                  <ListItemIcon>
                    {i18n.language === 'en' ? <img src={RO} height={20} width={20} /> : <img src={EN} height={20} width={20} />}
                  </ListItemIcon>
                  <ListItemText>{i18n.language === 'ro' ? 'Schimbă Limba' : "Switch Language"}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleOpenMenu()
                    handleLogout()
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.logout')}</ListItemText>
                </MenuItem>
              </MenuList>
            </SwipeableDrawer>
          </React.Fragment>
        </Grid>
      )}
      {!isMenuOpen && (
        <Grid display={{ xs: 'flex', lg: 'none' }} container flexDirection="row" pl={2}>
          <Grid xs={4} item>
            <Grid container>
              <Grid xs={12} style={{ cursor: 'pointer' }} item onClick={() => setOpenMenu()}>
                <Grid container flexDirection="row">
                  <Grid xs={4} item display="flex" flexDirection="row">
                    {isBusinessAdministrator || isClubAdministrator ? null : (
                      <Avatar src={isOrganization && user.picture ? user.picture : user.avatarPhotoSrc}>
                        {isOrganization && user.given_name ? `${user.given_name.charAt(0)} ${user.family_name.charAt(0)}` : `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`}
                      </Avatar>
                    )}
                    {!isBusinessAdministrator && !isClubAdministrator
                      ? null
                      : (isBusinessAdministrator || isClubAdministrator) && (
                        <Avatar src={user.companyAvatarPhotoSrc}>
                          {user && user.companyName ? user.companyName.charAt(0) : 'C'}
                        </Avatar>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={4}
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Logo onClick={() => user.role.toLowerCase() === ROLE_ORGANIZATION.toLocaleLowerCase() ? navigate('/') : navigate('/')} height={45} width={65} />
          </Grid>
          <Grid
            xs={4}
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            pr={2}
          >
            <Grid style={{ cursor: 'pointer' }} item pr={1.5}>
              <Message fontSize="small" style={{ color: CARD_TEXT_COLOR }} />
            </Grid>
            <Grid style={{ cursor: 'pointer' }} item>
              <BarChart fontSize="small" style={{ color: CARD_TEXT_COLOR }} />
            </Grid>
            {user.role.toLowerCase() === ROLE_ORGANIZATION.toLocaleLowerCase() &&
              <Grid pl={1.5} style={{ cursor: 'pointer' }} item>
                <MenuIcon fontSize="small" onClick={(event) => !matches1656px ? handleClick(event) : {}} />
              </Grid>
            }
          </Grid>
        </Grid>
      )}
      <Grid
        display={{ xs: 'none', lg: 'flex' }}
        height={'100%'}
        container
        flexDirection="row"
        alignItems="center"
      >
        <Grid xs={9} item>
          <Grid
            container
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            paddingRight={3}
          >
            {options.map((el, index) => {
              return (
                <Grid
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelected(el.name)
                    navigate(el.navigate)
                  }}
                  paddingLeft={{ xl: 1.5, md: 1, sm: 0, xs: 0 }}
                  paddingRight={{ xl: 1.5, md: 1, sm: 0, xs: 0 }}
                  key={index}
                  item
                >
                  <Grid container flexDirection="row" justifyContent="center" alignItems="center">
                    <Grid item pr={1} pt={0.3}>
                      {el.icon}
                    </Grid>
                    <Grid item>
                      <Typography
                        color={`${location && location.pathname && location.pathname.includes(el.name) ? 'primary' : ''}`}
                        fontWeight={600}
                        variant="p"
                      >
                        {el.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid xs={3} item>

          <Grid container justifyContent={user.role.toLowerCase() === ROLE_ORGANIZATION.toLocaleLowerCase() && 'flex-end'} alignItems="center">
            {user.role.toLowerCase() !== ROLE_ORGANIZATION.toLocaleLowerCase() &&
              <Grid xs={5.5} item>
                <Grid container paddingRight={2}>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    paddingLeft={1.5}
                    paddingRight={1.5}
                    xs={4}
                    item
                  >
                    <Search fontSize="medium" style={{ color: CARD_TEXT_COLOR }} />
                  </Grid>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    paddingLeft={1.5}
                    paddingRight={1.5}
                    xs={4}
                    item
                    id="message-basic-menu"
                  >
                    <AnnouncementIcon fontSize="medium" style={{ color: CARD_TEXT_COLOR }} />
                  </Grid>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    paddingLeft={1.5}
                    paddingRight={1.5}
                    xs={4}
                    item
                    id="basic-button"
                    aria-controls={openMenuNotifications ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenuNotifications ? 'true' : undefined}
                    onClick={handleClickNotifications}
                  >
                    <Badge
                      badgeContent={notifications.filter((el) => !el.read).length}
                      color="primary"
                    >
                      <Notifications fontSize="medium" style={{ color: CARD_TEXT_COLOR }} />
                    </Badge>
                  </Grid>
                </Grid>
              </Grid>}
            <Grid
              xs={6.5}
              style={{ cursor: 'pointer' }}
              item
              id="basic-button"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={(event) => matches1656px ? handleClick(event) : {}}
            >
              <Grid container flexDirection="row" justifyContent="center" alignItems="center">
                <Tooltip title={isOrganization ? `${user.organizationFullName}` : isUser || isTrainer ? `${user.firstName} ${user.lastName}` : `${user.companyName}`}>
                  <Grid
                    xs={!matches1656px ? 7 : 4}
                    item
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(
                        isUser
                          ? '/user-profile'
                          : isBusinessAdministrator || isClubAdministrator
                            ? '/business-profile'
                            : isTrainer
                              ? '/trainer-profile'
                              : isOrganization ? '/company-profile' : '/user-profile'
                      )
                    }}
                  >
                    {isBusinessAdministrator || isClubAdministrator ? null : (
                      <Avatar src={isOrganization && user.picture ? user.picture : user.avatarPhotoSrc}

                        style={{ objectFit: 'fill', height: 50, width: 50 }}
                      >
                        <Typography color={GRAY_TEXT_COLOR} fontWeight="bold">
                          {isOrganization && user.given_name ? `${user.given_name.charAt(0)} ${user.family_name.charAt(0)}` : `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`}
                        </Typography>
                      </Avatar>
                    )}
                    {!isBusinessAdministrator &&
                      !isClubAdministrator ? null : (isBusinessAdministrator || isClubAdministrator) && <Avatar
                        src={user.companyAvatarPhotoSrc}
                        style={{ objectFit: 'fill', height: 50, width: 50 }}
                      >
                        <Typography fontWeight="bold">
                          {user.firstName.charAt(0)} {user.lastName.charAt(0)}
                        </Typography>
                      </Avatar>}
                  </Grid>
                </Tooltip>
                <Grid xs={!matches1656px ? 5 : 3} item display="flex" flexDirection="row">
                  {matches1656px ? <Grid pl={0.5} pr={0.5} container flexDirection="column">

                  </Grid> : null}
                  <Grid display="flex" alignItems="center" justifyContent="center">
                    <MenuIcon onClick={(event) => !matches1656px ? handleClick(event) : {}} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!showSimpleMenu && renderCompaniesList}
            {showSimpleMenu && (
              <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
                <MenuItem
                  style={{ width: 350 }}
                  selected={location && location.pathname && location.pathname.includes('profile')}
                  onClick={handleProfile}
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.myProfile')}</ListItemText>
                </MenuItem>
                {!isClubAdministrator && <MenuItem
                  selected={location && location.pathname && location.pathname.includes('settings')}
                  onClick={() => {
                    navigate('/settings')
                    handleCloseMenu()
                  }}
                >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.settings')}</ListItemText>
                </MenuItem>}
                {user.role === ROLE_BUSINESS_ADMINISTRATOR ? <MenuItem
                  selected={location && location.pathname && location.pathname.includes('configurations')}
                  onClick={() => {
                    navigate('/configurations')
                    handleCloseMenu()
                  }}
                >
                  <ListItemIcon>
                    <Tune fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.configurations')}</ListItemText>
                </MenuItem> : null}
                {user.role === ROLE_USER ? <>
                  <Divider />
                  <MenuItem onClick={() => {
                    navigate('/recurring-payments')
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <RequestQuote />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.recurringPayments')}</ListItemText>
                  </MenuItem>
                </> : null}
                {isBusinessAdministrator || user.role === ROLE_USER || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <Divider /> : null}
                {user.role === ROLE_USER ? <MenuItem
                  selected={location && location.pathname && location.pathname.includes('payments')}
                  onClick={() => {
                    navigate('/payments')
                    handleCloseMenu()
                  }}
                >
                  <ListItemIcon>
                    <Payment />
                  </ListItemIcon>
                  <ListItemText>{isUser ? t("navbar.transactions") : t("navbar.payments")}</ListItemText>
                </MenuItem> : null}


                {/* {isBusinessAdministrator || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <MenuItem onClick={() => {
                  navigate("/price")
                  handleCloseMenu()
                }}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText>{t("navbar.option.price")}</ListItemText>
                </MenuItem> : null} */}

                {isBusinessAdministrator || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <MenuItem onClick={() => {
                  navigate("/reports")
                  handleCloseMenu()
                }}>
                  <ListItemIcon>
                    <Summarize />
                  </ListItemIcon>
                  <ListItemText>{t("navbar.option.reports")}</ListItemText>
                </MenuItem> : null}

                {isBusinessAdministrator || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <MenuItem onClick={() => {
                  navigate('/vouchers')
                  handleCloseMenu()
                }}>
                  <ListItemIcon>
                    <Discount />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.vouchers')}</ListItemText>
                </MenuItem> : null}

                {isBusinessAdministrator || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ? <MenuItem onClick={() => {
                  navigate("/employees")
                  handleCloseMenu()
                }}>
                  <ListItemIcon>
                    <AssignmentInd />
                  </ListItemIcon>
                  <ListItemText>{t("navbar.option.employees")}</ListItemText>
                </MenuItem> : null}

                {user.role === ROLE_BUSINESS_ADMINISTRATOR ? <>
                  <MenuItem onClick={() => {
                    navigate('/audit-logs')
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <PanoramaFishEye />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.auditLogs')}</ListItemText>
                  </MenuItem>
                </> : null}
                {user.role === ROLE_USER ? <>
                  <Divider />
                  <MenuItem onClick={() => {
                    setShowScanQr(true)
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <QrCode2 />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.scan')}</ListItemText>
                  </MenuItem>
                </> : null}

                {isBusinessAdministrator ? <Divider /> : null}

                {isBusinessAdministrator || user.role === ROLE_ORGANIZATION ?
                  <MenuItem onClick={async () => {
                    await openStripeBillingPortal(user.role === ROLE_ORGANIZATION ? 'organization' : 'company');
                    handleCloseMenu();
                  }}>
                    <ListItemIcon>
                      <RequestPageIcon />
                    </ListItemIcon>
                    <ListItemText>{t("navbar.option.billing")}</ListItemText>
                  </MenuItem> : null}

                {isBusinessAdministrator ?
                  <MenuItem onClick={() => {
                    navigate("/price")
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText>{t("navbar.option.price")}</ListItemText>
                  </MenuItem> : null}

                {user.role === ROLE_BUSINESS_ADMINISTRATOR ?
                  <MenuItem onClick={() => {
                    // navigate('/payouts')
                    window.open('https://dashboard.stripe.com/payouts')
                    handleCloseMenu()
                  }}>
                    <ListItemIcon>
                      <CallSplit />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.payouts')}</ListItemText>
                  </MenuItem> : null}

                {companiesCount > 0 ? <Divider /> : null}
                {isClubAdministrator && companiesCount > 0 && (
                  <MenuItem
                    style={{ width: 350 }}
                    selected={false}
                    onClick={() => {
                      EMPLOYEE_REQUESTS.switchRoleBack().then((response) => {
                        handleLoggedIn(true)
                        handleToken(response.token)
                        handleUser(response.user)
                        localStorage.removeItem("companyId")
                        localStorage.setItem('token', response.token)
                        navigate('/')
                        handleCloseMenu()
                      })
                    }}
                  >
                    <ListItemIcon>
                      <SwitchLeft />
                    </ListItemIcon>
                    <ListItemText>
                      {t('navbar.switchTo')}{' '}
                      <b>
                        {user.firstName} {user.lastName}
                      </b>
                    </ListItemText>
                  </MenuItem>
                )}
                {companiesCount > 0 && (
                  <MenuItem
                    selected={
                      location && location.pathname && location.pathname.includes('profile')
                    }
                    style={{ width: 350 }}
                    onClick={handleProfile}
                  >
                    <ListItemIcon>
                      <Apartment />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.companyProfile')}</ListItemText>
                  </MenuItem>
                )}
                {companiesCount > 0 && (
                  <MenuItem
                    style={{ display: 'flex', aligItems: 'center', flexDirection: 'row' }}
                    onClick={() => {
                      setShowSimpleMenu(false)
                    }}
                  >
                    <ListItemIcon>
                      <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.clubs')}</ListItemText>
                    <ArrowForwardIos fontSize="small" />
                  </MenuItem>
                )}
                {isBusinessAdministrator && (
                  <MenuItem
                    selected={false}
                    onClick={() => {
                      navigate('/business-create')
                      handleCloseMenu()
                    }}
                  >
                    <ListItemIcon>
                      <Add fontSize="small" />{' '}
                    </ListItemIcon>
                    <ListItemText>{t('navbar.createClub')}</ListItemText>
                  </MenuItem>
                )}
                <Divider />
                {user.role === ROLE_ORGANIZATION || user.role === ROLE_BUSINESS_ADMINISTRATOR ?
                  <MenuItem
                    onClick={() => {
                      window.location.href = `${CHALLENGES_URL}${token}`;
                    }}
                  >
                    <ListItemIcon>
                      <DirectionsWalkIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('navbar.challenges')}</ListItemText>
                  </MenuItem> : null}
                <MenuItem
                  onClick={() => {
                    window.open('https://help.sportin.io/en')
                  }}
                >
                  <ListItemIcon>
                    <Info fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.userManual')}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    USER_REQUESTS.updateLanguage(user.id, i18n.language === 'en' ? 'RO' : 'ENG').then(() => {
                      i18n.changeLanguage(i18n.language === 'en' ? 'ro' : 'en')
                      localStorage.setItem("newLanguage", i18n.language === 'en' ? 'en' : 'ro')
                    })
                  }}
                >
                  <ListItemIcon>
                    {i18n.language === 'en' ? <img src={RO} height={20} width={20} /> : <img src={EN} height={20} width={20} />}
                  </ListItemIcon>
                  <ListItemText>{i18n.language === 'ro' ? 'Schimbă Limba' : "Switch Language"}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>
                  <ListItemText>{t('navbar.logout')}</ListItemText>
                </MenuItem>
              </Menu>
            )}

            {renderNotificationList}
          </Grid>
        </Grid>
      </Grid >
    </>
  )
}

export default Navbar

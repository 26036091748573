import React, { useState, useCallback, useEffect } from 'react'
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { MoreVert, Money } from '@mui/icons-material'

import { Table, SimplePagination } from '../../common'

import * as COMPANY_REQUESTS from '../../../api/company'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { ERROR_COLOR, GREEN_COLOR, PRIMARY_COLOR } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const PAGE_SIZE = 35

const CalendarServiceBookings = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [bookingsTotalCount, setBookingsTotalCount] = useState(0)
  const [bookings, setBookings] = useState([])
  const [selectedBooking, setSelectedBooking] = useState()
  const [anchorElement, setAnchorElement] = useState()

  const getCalendarServiceBookings = useCallback(() => {
    return COMPANY_REQUESTS.getCalendarServiceBookings({
      from: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      search
    }).then((res) => {
      setBookings(res)
      setBookingsTotalCount(res.length)
    })
  }, [page, search])

  const handleConfirmPayment = useCallback(async (booking) => {
    try {
      await COMPANY_REQUESTS.confirmBookingPayment(booking.id)
      getCalendarServiceBookings()
      setSelectedBooking()
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    getCalendarServiceBookings()
  }, [page, search])

  return (
    <Grid container pt={3} pb={3}>
      <Grid item pb={2} xs={12}>
        <Grid container flexDirection="row" display="flex">
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Typography variant="h5" fontWeight="bold">
              Client Service Bookings
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} pb={5}>
        <Table
          dense
          headCells={[
            t('clients.tableHeadUser.fullName'),
            t('clients.tableHeadUser.phoneNumber'),
            t('clients.tableHeadUser.email'),
            'Service',
            'From',
            'To',
            'Selfin',
            'Payment Method',
            'Confirmed'
          ]}
          rows={bookings.map((booking) => {
            return {
              fullName: (
                <Typography
                  onClick={() => navigate(`/athlete/${booking.user.id}`)}
                  color={booking?.user.isDeleted ? ERROR_COLOR : PRIMARY_COLOR}
                  sx={{ fontWeight: 700, textDecoration: 'underline', cursor: 'pointer' }}>
                  {`${booking?.user.firstName} ${booking?.user.lastName}`}
                  {booking?.user.isDeleted && (
                    <Typography>{'(Deleted)'}</Typography>
                  )}
                </Typography>
              ),
              phoneNumber: (
                <Typography fontWeight={'500'}>
                  {`${
                    booking?.user.phoneNumber?.split('.deleted.')?.[0] || booking?.user.phoneNumber
                  }`}
                </Typography>
              ),
              email: (
                <Typography fontWeight={'500'}>
                  {booking?.user.email.includes('.deleted.')
                    ? `${booking.user.email.split('.deleted.')[0]}@${booking.user.email.split('@')[1]}`
                    : booking?.user.email}
                </Typography>
              ),
              service: <Typography fontWeight={'500'}>{`${booking.service}`}</Typography>,
              from: (
                <Typography fontWeight={'500'}>
                  {`${moment(booking.from).format('YYYY-MM-DD HH:mm')}`}
                </Typography>
              ),
              to: (
                <Typography fontWeight={'500'}>
                  {`${moment(booking.to).format('YYYY-MM-DD HH:mm')}`}
                </Typography>
              ),
              selfin: <Typography fontWeight={'500'}>{`WIP`}</Typography>,
              paymentMethod: (
                <Typography fontWeight={'500'}>
                  {`${
                    Object.keys(booking.subscription).length > 0
                      ? booking.subscription.name
                      : booking.paymentMethod
                      ? booking.paymentMethod
                      : 'Unknown'
                  }`}
                </Typography>
              ),
              confirmed: (
                <Typography
                  sx={{ textAlign: 'center' }}
                  fontWeight={'700'}
                  color={booking.paymentConfirmed ? GREEN_COLOR : ERROR_COLOR}>
                  {`${booking.paymentConfirmed ? 'Yes' : 'No'}`}
                </Typography>
              ),
              actiuni: (
                <Box>
                  <IconButton
                    onClick={(event) => {
                      setAnchorElement(event.currentTarget)
                      setSelectedBooking(booking)
                    }}>
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElement}
                    open={selectedBooking && selectedBooking.id === booking.id}
                    onClose={() => {
                      setAnchorElement(null)
                      setSelectedBooking()
                    }}>
                    <MenuItem disabled>{t('clients.tableHead.actions')}</MenuItem>
                    <Divider />
                    <MenuItem
                      disabled={booking.paymentConfirmed}
                      onClick={() => {
                        handleConfirmPayment(booking)
                      }}>
                      <ListItemIcon>
                        <Money fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Confirm Payment</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
              )
            }
          })}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <SimplePagination
          count={Math.ceil(Number(bookingsTotalCount / PAGE_SIZE))}
          onPageChange={setPage}
        />
      </Grid>
    </Grid>
  )
}

export default CalendarServiceBookings
